// @ts-nocheck
import React from "react";
import { TOKEN } from "../api/constants";

export const getToken = () => {
  const tokenString = JSON.parse(localStorage.getItem(TOKEN));
  return tokenString;
};
export const saveToken = (userToken) => {
  localStorage.setItem(TOKEN, JSON.stringify(userToken));
};
