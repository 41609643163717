import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { maleAvatar } from "../assets/img";
import PatientAppoinment from "../component/PatientAppoinment";
import TopHeader from "../component/TopHeader";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import patientService from "../Services/Service/patient";

const PatientDetail = () => {
  const location = useLocation();
  const [input, setInput] = useState({});
  const [data, setData] = useState([]);
  const appoitmentHistoryList = input?.appointments;
  // console.log("coming to page")
  // console.log(input);
  // console.log(appoitmentHistoryList);
  // console.log("appoiment");
  const viewDetails = () => {
    patientService
      .patientView(location?.state?.id)
      .then((res) => {
        setInput(res.data?.patient);
      })
      .catch((e) => {
        ErrorHandler(e);
      });
  };
  useEffect(() => {
    if (location?.state?.data?.status == 7) {
      setInput(location?.state?.data);
    } else if (location?.state?.id && location?.state?.data?.status !== 7) {
      viewDetails();
    }
  }, []);

  return (
    <aside className="">
      <TopHeader type="patient-detail" />
      <article>
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <div className="flex-ac-jb">
              <p
                className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                htmlFor=""
              >
                {input?.name}
              </p>
              <img
                className="avatar-img"
                src={input?.img_url ? input?.img_url : maleAvatar}
              />
            </div>
            <Form className="d-flex flex-wrap">
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" htmlFor="">
                      Name
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="example-if-email"
                      placeholder="Name"
                      value={input?.name}
                      className="bg-transparent"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" htmlFor="">
                      Mobile
                    </label>
                    <input
                      type="number"
                      disabled
                      value={input?.mobile}
                      id="example-if-email"
                      name="example-if-email"
                      placeholder="Mobile Number"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" htmlFor="">
                      Email
                    </label>
                    <input
                      type="email"
                      id="example-if-email"
                      name="example-if-email"
                      placeholder="Name"
                      value={input?.email}
                      className="bg-transparent"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" htmlFor="">
                      Gender
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="example-if-email"
                      placeholder="Name"
                      value={input?.gender}
                      className="bg-transparent"
                      disabled
                    />
                  </div>
                </div>
              </div>
              {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Patient Id
                    </label>
                    <div>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 8, 9].map((list, i) => {
                        return (
                          <span className="badge rounded-pill bg-primary mx-2 my-1">
                            {`patient id ${i + 1}`}
                          </span>
                        );
                      })}
                    </div>
                  
                  </div>
                </div>
              </div> */}
              {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Hospital2 Id
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="example-if-email"
                      placeholder="Name"
                      value="HS2P107"
                      className="bg-transparent"
                      disabled
                    />
                  </div>
                </div>
              </div> */}

              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" htmlFor="">
                      Age
                    </label>
                    <input
                      // type="date"
                      id="example-if-email"
                      name="example-if-email"
                      placeholder="Name"
                      value={input?.age}
                      className="bg-transparent"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" htmlFor="">
                      Address
                    </label>
                    <input
                      // type="date"
                      id="example-if-email"
                      name="example-if-email"
                      placeholder="Name"
                      value={input?.address}
                      className="bg-transparent"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </Form>
            {/* <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
              <button className="comn-btn bg-hash2 px-3 py-2 me-2">
                Cancel
              </button>
              <button
                type="button"
                className="comn-btn bg-greens px-3 py-2 me-2"
                data-dismiss="modal"
              >
                Submit
              </button>
            </div> */}
          </div>
        </div>
      </article>
      {appoitmentHistoryList?.length === 0 ? null : (
        <PatientAppoinment data={appoitmentHistoryList} />
      )}
    </aside>
  );
};

export default PatientDetail;
