import React from "react";
import { Outlet } from "react-router-dom";
import Header1 from "../component/Header1";

const Layout1 = () => {
  return (
    <div className="d-flex flex-wrap">
      <Header1 />
      <div className="out-left col ">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout1;
