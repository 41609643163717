import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import ReactRoutes from "./routes";
import httpClient from "./Services/Constant/httpClient";
import useToken from "./Services/Storage/useToken";
import LoadingOverlay from "react-loading-overlay";
import { HashRouter as Router } from "react-router-dom";
import Loader from "react-loader";
import disableScroll from "disable-scroll";

const App = () => {
  const { token, setToken } = useToken();
  const [loading, setLoading] = useState(false);

  // const auth = localStorage?.getItem("TOKEN");
  // console.log(auth, token);

  if (token) {
    httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  useEffect(() => {
    httpClient.interceptors.request.use(
      function (config) {
        // spinning start to show
        // setTimeout(() => {
        //   setLoading(true);

        // }, 1000);
        disableScroll.on();
        setLoading(true);
        return config;
      },
      function (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    );

    httpClient.interceptors.response.use(
      function (response) {
        // spinning hide
        // setTimeout(() => {
        //   console.log("response");
        //   setLoading(false);
        // }, 1000);

        disableScroll.off();
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        return response;
        // return () => clearTimeout(timer);
        // setLoading(false);
        // return response;
      },
      function (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    // <LoadingOverlay
    //   active={loading}
    //   // spinner={<BounceLoader />}
    //   spinner={loading}
    //   // text="Loading your content..."
    // >
    <React.Fragment>
      <Loader
        loaded={!loading}
        lines={12}
        length={12}
        width={4}
        radius={25}
        corners={2}
        rotate={0}
        direction={-1}
        color="#FF5A6A"
        speed={1}
        trail={60}
        shadow={false}
        hwaccel={true}
        className="spinner"
        zIndex={2e9}
        top="50%"
        left="55%"
        scale={1.0}
        loadedClassName="loadedContent"
      />

      {/* <Router> */}
      <ReactRoutes />
      {/* </Router> */}
    </React.Fragment>
    // </LoadingOverlay>
  );
};

export default App;
