import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Tab, Tabs } from "react-bootstrap";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { supportServices } from "../Services/Service/supportService";
import ReactPaginate from "react-paginate";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import swal from "sweetalert";

const HospitalSupport = () => {
  const [adminData, setadminData] = useState([]);
  const [resolved, setResolved] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [banner, setBanner] = useState();
  const [bannerPreview, setBannerPreview] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);

  const [searcharr, setSearchArr] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");

  const [supportId, setSupportId] = useState();

  const [requestitemsPerPage, setRequestItemsPerPage] = useState(10);

  const [requestcurrentItems, setRequestCurrentItems] = useState([]);
  const [requestpageCount, setRequestPageCount] = useState(0);
  const [requestcounter, setRequestCounter] = useState(0);
  const [requestlast, setRequestLast] = useState(10);
  const [requestitemOffset, setRequestItemOffset] = useState(0);

  const [requestsearcharr, setRequestSearchArr] = useState("");

  useEffect(() => {
    let temp = [...resolved];

    if (requestsearcharr == "") {
      const endOffset = requestitemOffset + requestitemsPerPage;

      setRequestCurrentItems(resolved?.slice(itemOffset, endOffset));
      setRequestPageCount(Math.ceil(resolved?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.description.toLowerCase().includes(requestsearcharr.toLowerCase())
      );

      setRequestCurrentItems(datas);
    }
  }, [requestsearcharr]);

  useEffect(() => {
    const endOffset = requestitemOffset + requestitemsPerPage;

    setRequestCurrentItems(resolved?.slice(itemOffset, endOffset));
    setRequestPageCount(Math.ceil(resolved?.length / itemsPerPage));
  }, [requestitemOffset, requestitemsPerPage, resolved]);

  const requesthandlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % resolved?.length;
    setRequestItemOffset(newOffset);

    let temp2 = event.selected;
    setRequestCounter(temp2);

    let temp = event.selected + 1 + "0";
    setRequestLast(temp);
  };

  const requestSetListItems = (e) => {
    const value = e.target.value;
    setRequestItemsPerPage(parseInt(value));
  };

  useEffect(() => {
    let temp = [...adminData];

    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;

      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.description.toLowerCase().includes(searcharr.toLowerCase())
      );

      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    console.log(event);
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;

    setItemsPerPage(parseInt(value));
  };

  const supportList = () => {
    supportServices
      .hospitalSupportList()
      .then((res) => {
        let temp = res.data?.supports.reverse();
        setadminData(temp);
        console.log(temp);
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    supportList();
    supportLists();
  }, []);

  const supportLists = () => {
    supportServices
      .hospitalReplyiedSupport()
      .then((res) => {
        let temp = res.data?.supports.reverse();
        setResolved(temp);
        console.log(res.data);
        // setadminData(res.data.supports)
      })
      .catch((e) => ErrorHandler(e));
  };

  const viewSupport = (id) => {
    setAddModal(true);
    supportServices.viewSupport(id).then((res) => {
      setName(res.data.support.supportable.authorized_name);
      setSupportId(res.data.support.id);
    });
  };

  const replyMessage = () => {
    const payload = {
      description: message,
    };
    console.log(payload);
    supportServices
      .raiseSupport(payload)
      .then((res) => {
        swal(res.data.message, "", "success");
        supportLists();
        supportList();
        setAddModal(false);
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    if (!banner) {
      setBannerPreview(undefined);
      return;
    }
    const profileUrl = URL.createObjectURL(banner);
    setBannerPreview(profileUrl);
  }, [banner]);

  const onClickImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setBanner(undefined);
      return;
    }
    setBanner(e.target.files[0]);
  };
  return (
    <aside>
      <TopHeader type="help-support" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="raised"
          id="uncontrolled-tab-example"
          className="tab-appoinment"
          fill
        >
          <Tab eventKey="raised" title="Raised">
            <div className="ad-btn w-100 flex-ac-jb p-2">
              <div />
              {/* <Form>
                <select onChange={setListItems} name="" id="">
                  <option value="10">10</option>
                  <option value={adminData.length}>All</option>
                </select>
              </Form> */}
              <div className="flex-ac-jc">
                <Form className="search-input me-2">
                  <input
                    onChange={(e) => setSearchArr(e.target.value)}
                    placeholder="search..."
                    type="search"
                    className="bg-transparent rounded-1 px-2 py-1"
                  />
                </Form>
                <button
                  onClick={() => {
                    setAddModal(true);
                  }}
                  className="comn-btn bg-primary1 px-3 py-2"
                >
                  Support
                </button>
              </div>
            </div>
            <div className="p-2 bg-white rounded-2">
              <table className="custom-table">
                <thead>
                  <tr className="text-center">
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Query</th>
                    <th>Type</th>
                    <th>Action</th>

                    {/* <th className="text-center">Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((list, index) => (
                    <tr key={index} className="text-center">
                      <td>
                        {index == 9
                          ? last
                          : counter
                          ? `${counter}${index + 1}`
                          : index + 1}
                      </td>
                      <td style={{ width: "450px" }}>
                        {list?.supportable?.name}
                      </td>
                      <td style={{ width: "450px" }}>{list.description}</td>
                      {list.supportable_type == "App\\Models\\Hospital" && (
                        <td>Hospital</td>
                      )}
                      {list.supportable_type == "App\\Models\\Doctor" && (
                        <td>Individual Doctor</td>
                      )}
                      {list.supportable_type == "App\\Models\\User" && (
                        <td>User</td>
                      )}

                      {/* <td className="text-center">
                    <button
                      className={
                        list.status == "Active"
                          ? "comn-btn active-deact-btn bg-greens"
                          : "comn-btn active-deact-btn bg-primary1"
                      }
                    >
                      {list.status}
                    </button>
                  </td> */}
                      <td className="flex-ac text-center">
                        <button
                          onClick={() => viewSupport(list.id)}
                          className="comn-btn bg-yellows edit-btn-svg me-2"
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
              <ReactPaginate
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="<"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </Tab>

          <Tab eventKey="resolved" title="Resolved">
            <div className="ad-btn w-100 flex-ac-jb p-2">
              <div />
              {/* <Form>
                <select onChange={requestSetListItems} name="" id="">
                  <option value="10">10</option>
                  <option value={resolved.length}>All</option>
                </select>
              </Form> */}
              <div className="flex-ac-jc">
                <Form className="search-input me-2">
                  <input
                    onChange={(e) => setRequestSearchArr(e.target.value)}
                    placeholder="search..."
                    type="search"
                    className="bg-transparent rounded-1 px-2 py-1"
                  />
                </Form>
              </div>
            </div>
            <div className="p-3 bg-white rounded-2">
              <table className="custom-table">
                <thead>
                  <tr className="text-center">
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Query</th>
                    <th>Reply</th>

                    {/* <th className="text-center">Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {requestcurrentItems.map((list, index) => (
                    <tr key={index} className="text-center">
                      <td>
                        {index == 9
                          ? last
                          : counter
                          ? `${counter}${index + 1}`
                          : index + 1}
                      </td>
                      <td>{list?.supportable?.name}</td>
                      <td>
                        <div style={{ width: "300px" }}>{list.description}</div>
                      </td>
                      <td style={{ width: "450px" }}>{list.reply_message}</td>

                      {/* <td className="text-center">
                    <button
                      className={
                        list.status == "Active"
                          ? "comn-btn active-deact-btn bg-greens"
                          : "comn-btn active-deact-btn bg-primary1"
                      }
                    >
                      {list.status}
                    </button>
                  </td> */}
                      {/* <td className="flex-ac text-center">
                        <button
                          onClick={() => viewSupport(list.id)}
                          className="comn-btn bg-yellows edit-btn-svg me-2"
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </button>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
              <ReactPaginate
                nextLabel=">"
                onPageChange={requesthandlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={requestpageCount}
                previousLabel="<"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
            {/* <Pagination className="mt-2 custom-pagi-nation justify-content-end align-items-center">
              {items}
            </Pagination> */}
          </Tab>
        </Tabs>
      </article>

      <Modal
        className="table-modal"
        show={addModal}
        onHide={() => setAddModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Raise Support</h4>
          </div>

          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Queries
            </label>
            <textarea
              value={message}
              placeholder="Queries"
              onChange={(e) => setMessage(e.target.value)}
              id=""
              cols="30"
              rows="10"
              className="message-txt"
            />
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => setAddModal(false)}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Close
          </button>
          <button
            onClick={() => replyMessage()}
            type="button"
            className="comn-btn bg-greens px-3 py-2 me-2"
            data-dismiss="modal"
          >
            Reply
          </button>
        </div>
      </Modal>
      <Modal
        className="table-modal"
        show={editModal}
        onHide={() => setEditModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Reply</h4>
          </div>
          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Voium Niou
            </label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              className="message-txt"
              disabled
            >
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laborum
              id recusandae ut officiis dolorum voluptates, necessitatibus at
              perferendis, delectus eveniet porro beatae excepturi vitae rerum
              vel temporibus fugit optio soluta.
            </textarea>
          </div>
          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Description
            </label>
            <textarea name="" id="" cols="30" rows="10"></textarea>
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => setEditModal(false)}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Cancel
          </button>
          <button
            type="button"
            className="comn-btn bg-greens px-3 py-2 me-2"
            data-dismiss="modal"
          >
            Submit
          </button>
        </div>
      </Modal>
    </aside>
  );
};

export default HospitalSupport;
