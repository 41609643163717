import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

const RejectedTable = ({ adminData }) => {
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);

  const [searcharr, setSearchArr] = useState("");

  useEffect(() => {
    let temp = [...adminData];

    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;

      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.name.toLowerCase().includes(searcharr.toLowerCase())
      );

      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    console.log(event);
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;

    setItemsPerPage(parseInt(value));
  };

  const changeStatus = (id) => {
    //   doctor.doctorList(id)
    //     .thn((res) => {
    //       alert
    //     })
    //     .catch((e) => {
    //       ErrorHandler(e);
    //     });
  };

  return (
    <>
      <div className="ad-btn w-100 flex-ac-jb p-2">
        <Form>
          <select onChange={setListItems} name="" id="">
            <option value="10">10</option>
            <option value={adminData?.length}>All</option>
          </select>
        </Form>
        <div className="flex-ac-jc">
          <Form className="search-input me-2">
            <input
              onChange={(e) => setSearchArr(e.target.value)}
              placeholder="search..."
              type="search"
              className="bg-transparent rounded-1 px-2 py-1"
            />
          </Form>
        </div>
      </div>

      <div className="p-2 bg-white rounded-2">
        <table className="custom-table_new">
          <thead>
            <tr className="text-center">
              <th>S.No</th>
              <th>Name</th>
              <th>Mobile</th>
              <th>Email</th>
              {/* <th>Ratings</th> */}
              {/* <th className="text-center">Status</th> */}
            </tr>
          </thead>
          <tbody>
            {currentItems.map((list, index) => (
              <tr key={index} className="text-center">
                <td>
                  {index == 9
                    ? last
                    : counter
                    ? `${counter}${index + 1}`
                    : index + 1}
                </td>
                <td>{list.name}</td>
                <td>{list?.detail?.mobile}</td>
                <td>{list?.detail?.email || "Not Mentioned"}</td>

                {/* <td>{list.rating}</td> */}
                {/* <td className="text-center">
                  <button
                    onClick={() => changeStatus()}
                    className={
                      list.status == "Active"
                        ? "comn-btn active-deact-btn bg-greens"
                        : "comn-btn active-deact-btn bg-primary1"
                    }
                  >
                    {list.status}
                  </button>
                </td> */}
                {/* <td className="flex-ac text-center">
                  <Link
                    to="/doctor/doctor-detail"
                    className="comn-btn bg-yellows edit-btn-svg"
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Link>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

export default RejectedTable;
