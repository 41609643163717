import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { Form, Modal } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
let active = 3;
let items = [];
for (let number = 1; number <= 4; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>
  );
}
// const data = [
//   {
//     id: 1,
//     name: "Poily Vayali",
//     status: "Active",
//     category: "Orthology",
//   },
//   {
//     id: 2,
//     name: "Ion Pothem",
//     status: "Deactive",
//     category: "Chemotherapy",
//   },
//   {
//     id: 3,
//     name: "Ramesh",
//     status: "Active",
//     category: "Diabetes",
//   },
//   {
//     id: 4,
//     name: "Chakaravarthy",
//     status: "Deactive",
//     category: "Cardiology",
//   },
// ];

const HospitalCategories = (props) => {
  console.log(props?.data);
  let data = props?.data;
  const [adminData, setadminData] = useState(data ? data : "");
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [activeStatus, setActiveStatus] = useState(data.status);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [searcharr, setSearchArr] = useState("");

  useEffect(() => {
    setadminData(data);
  }, [data]);

  useEffect(() => {
    let temp = [...adminData];
    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;

      setCurrentItems(data?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.name.toLowerCase().includes(searcharr.toLowerCase())
      );
      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    console.log(event);
    const newOffset = (event.selected * itemsPerPage) % data?.length;
    setItemOffset(newOffset);
    let temp2 = event.selected;
    setCounter(temp2);
    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;
    setItemsPerPage(parseInt(value));
  };

  return (
    <div className="p-3">
      <div className="ad-btn w-100 flex-ac-jb p-2">
        <div />
        {/* <Form>
          <select onChange={setListItems} name="" id="">
            <option value="10">10</option>
            <option value={adminData.length}>All</option>
          </select>
        </Form> */}
        <div className="flex-ac-jc">
          <Form className="search-input me-2">
            <input
              onChange={(e) => setSearchArr(e.target.value)}
              placeholder="search..."
              type="search"
              className="bg-transparent rounded-1 px-2 py-1"
            />
          </Form>
        </div>
      </div>
      <div className="p-3 bg-white rounded-2">
        <p
          className="permission-text txt-primary4 mb-1 mt-0 text-uppercase"
          for="">
          Doctor List
        </p>
        <table className="custom-table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Speciality</th>
              <th className="text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((list, index) => (
              <tr key={index}>
                <td>
                  {index == 9
                    ? last
                    : counter
                    ? `${counter}${index + 1}`
                    : index + 1}
                </td>
                <td>{list.name}</td>
                <td className="d-flex flex-wrap">
                  {list?.speacialists?.map((item, ind) => (
                    <span className="pe-1">{item?.name + ","}</span>
                  ))}
                </td>
                <td className="text-center">
                  <button
                    className={
                      list.status == 1
                        ? "comn-btn active-deact-btn bg-greens"
                        : "comn-btn active-deact-btn bg-primary1"
                    }>
                    {list.status == 1 ? "Active" : "deactive"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <Pagination className="mt-2 custom-pagi-nation justify-content-end align-items-center">
        {items}
      </Pagination> */}
      <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
      <Modal
        className="table-modal"
        show={addModal}
        onHide={() => setAddModal(false)}
        aria-labelledby="example-modal-sizes-title-lg">
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Add Categories</h4>
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Name
            </label>
            <input
              type="Name"
              id="example-if-email"
              name="example-if-email"
              placeholder="Name"
            />
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Treatments
            </label>
            <select name="" id="">
              <option value="Super Admin">Diabetes</option>
              <option value="Admin">Cardiology</option>
              <option value="Admin">Chemotherapy</option>
              <option value="Admin">Orthopedics</option>
            </select>
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => setAddModal(false)}
            className="comn-btn bg-hash2 px-3 py-2 me-2">
            Cancel
          </button>
          <button
            type="button"
            className="comn-btn bg-greens px-3 py-2 me-2"
            data-dismiss="modal">
            Submit
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default HospitalCategories;
