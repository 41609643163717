import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link, Navigate, useLocation } from "react-router-dom";
import { bannerList, maleAvatar } from "../assets/img";
import DoctorTreatment from "../component/DoctorTreatment";
import DoctorTreatment1 from "../component/DoctorTreatment1";
import PatientAppoinment from "../component/PatientAppoinment";
import TopHeader from "../component/TopHeader";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import { doctor } from "../Services/Service/doctor";
import moment from "moment";
const DoctorDetails = () => {
  const location = useLocation();
  const [list, setList] = useState([]);
  const [searchArr, setSearchArr] = useState("");
  const [listLimit, setListLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const viewDoctor = () => {
    doctor.viewDoctor(location?.state?.id).then((res) => {
      setList(res.data?.doctor);
    });
  };

  console.log("location", location?.state?.data);

  useEffect(() => {
    if (location?.state?.data?.status == 7) {
      setList(location?.state?.data);
    } else if (location?.state?.id && location?.state?.data?.status !== 7) {
      viewDoctor();
    }
  }, []);

  console.log("list", list);

  const overAllPages = Math.ceil(list?.appointments?.length / listLimit);
  console.log(overAllPages);
  let pages = [];
  for (let i = 1; i <= overAllPages; i++) {
    pages.push(i);
  }
  console.log(pages);

  const indexofLastPage = currentPage * listLimit;
  const indexofFirstPage = indexofLastPage - listLimit;
  const pageData = list?.appointments?.slice(indexofFirstPage, indexofLastPage);
  // console.log(pageData);

  const prevPage = () => {
    currentPage !== 1 && setCurrentPage(currentPage - 1);
  };
  const nextPage = () => {
    currentPage !== overAllPages && setCurrentPage(currentPage + 1);
  };
  const setListItems = (e) => {
    const value = e.target.value;
    setListLimit(parseInt(value));
    console.log(value);
  };
  return (
    <aside className="">
      <TopHeader type="doctor-detail" />
      <article>
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <div className="flex-ac-jb">
              <p
                className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                for=""
              >
                {list?.name}
              </p>
              <img
                className="avatar-img"
                src={list?.img_url ? list?.img_url : maleAvatar}
              />
            </div>
            <Form className="d-flex flex-wrap">
              {list?.name && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Name
                      </label>
                      <input
                        type="Name"
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Name"
                        value={list?.name}
                        className="bg-transparent"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.detail?.mobile && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Mobile
                      </label>
                      <input
                        type="Name"
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Name"
                        value={list?.detail?.mobile}
                        className="bg-transparent"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.detail?.email && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Email
                      </label>
                      <input
                        type="email"
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Name"
                        value={list?.detail?.email}
                        className="bg-transparent"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.detail?.gender && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Gender
                      </label>
                      <input
                        type="Name"
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Gender"
                        value={list?.detail?.gender}
                        className="bg-transparent"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.experience && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Year Of Experience
                      </label>
                      <input
                        // type="date"
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Experience"
                        value={list?.experience}
                        className="bg-transparent"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.speacialists?.length !== 0 && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Specialities
                      </label>
                      <input
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Specialities"
                        value={list?.speacialists?.map((list) => {
                          return list?.name;
                        })}
                        className="bg-transparent"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.interval_mins && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Duration ( Min )
                      </label>
                      <input
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Duration"
                        value={list?.interval_mins}
                        className="bg-transparent"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.total_fees && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Appointment Fee ( ₹ )
                      </label>
                      <input
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Appointment"
                        value={list?.total_fees}
                        className="bg-transparent"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.bank_detail?.account_holder_name && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Account Holder Name
                      </label>
                      <input
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Appointment"
                        value={list?.bank_detail?.account_holder_name}
                        className="bg-transparent"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.bank_detail?.account_number && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Account Number
                      </label>
                      <input
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Appointment"
                        value={list?.bank_detail?.account_number}
                        className="bg-transparent"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.bank_detail?.ifsc_code && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        IFSC
                      </label>
                      <input
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Appointment"
                        value={list?.bank_detail?.ifsc_code}
                        className="bg-transparent"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.bank_detail?.business_name && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Business Name
                      </label>
                      <input
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Appointment"
                        value={list?.bank_detail?.business_name}
                        className="bg-transparent"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.bank_detail?.pan_no && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        PAN No
                      </label>
                      <input
                        id="example-if-Pan"
                        name="example-if-pan"
                        placeholder="PAN"
                        value={list?.bank_detail?.pan_no}
                        className="bg-transparent"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.bank_detail?.gst_no && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        GST No
                      </label>
                      <input
                        id="example-if-Gst"
                        name="example-if-Gst"
                        placeholder="GST"
                        value={list?.bank_detail?.gst_no}
                        className="bg-transparent"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.rating && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ratings
                      </label>
                      <input
                        // type="date"
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Ratings"
                        value={list?.rating}
                        className="bg-transparent"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.bank_detail && (
                <div className="form-group col-12 col-sm-12 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Address
                      </label>
                      <input
                        value={`${
                          list?.bank_detail?.street_1 &&
                          list?.bank_detail?.street_1
                        }, ${
                          list?.bank_detail?.street_2 &&
                          list?.bank_detail?.street_2
                        }, ${
                          list?.bank_detail?.city && list?.bank_detail?.city
                        }, ${
                          list?.bank_detail?.state && list?.bank_detail?.state
                        }, ${
                          list?.bank_detail?.country &&
                          list?.bank_detail?.country
                        } - ${
                          list?.bank_detail?.pin_code &&
                          list?.bank_detail?.pin_code
                        }`}
                        // type="date"
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Address"
                        // value={list?.rating}
                        className="bg-transparent"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.detail?.description && (
                <div className="form-group col-12 col-sm-12 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Description
                      </label>
                      <p
                        className="text-primary-dark textarea-height address-request"
                        for=""
                      >
                        {list?.detail?.description}
                      </p>
                      {/* <p className="bg-transparent textarea-height ">
                      {list.detail.description}
                    </p> */}
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="form-group col-12 col-sm-6 col-lg-4 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      MUI
                    </label>
                    <input type="file" onChange={onClickMui} />
                    {mui && (
                      <div className="w-90 prev-Image">
                        <img className="w-100" src={muiPreview} />
                      </div>
                    )}
                  </div>
                </div>
              </div> */}
              {list?.img_url && (
                <div className="form-group col-12 col-sm-12 col-lg-7 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Licence
                      </label>
                      <div className="w-90 prev-Image">
                        <img className="w-100" src={list?.img_url} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Form>
          </div>
        </div>
      </article>

      <div className="p-3">
        <div className="ad-btn w-100 flex-ac-jb p-2">
          <Form>
            <select onChange={setListItems} name="" id="">
              <option value="10">10</option>
              <option value="50">50</option>
              <option value={list?.appointments?.length}>All</option>
            </select>
          </Form>
          <div className="flex-ac-jc">
            <Form className="search-input me-2">
              <input
                onChange={(e) => setSearchArr(e.target.value)}
                placeholder="search..."
                type="search"
                className="bg-transparent rounded-1 px-2 py-1"
              />
            </Form>
          </div>
        </div>{" "}
        <div className="p-3 bg-white rounded-2">
          <p
            class="permission-text txt-primary4 mb-1 mt-2 text-uppercase"
            for=""
          >
            Appointment History
          </p>
          <table className="custom-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th className="text-center">Hospital Name</th>
                <th className="text-center">Patient Id</th>
                <th className="text-center">Doctor</th>
                <th className="text-center">Cateories</th>
                <th className="text-center">Date</th>
                <th className="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              {(searchArr?.toLowerCase() === "" ? pageData : list?.appointments)
                ?.filter((item) => {
                  return searchArr?.toLowerCase() === ""
                    ? item
                    : item?.speciality?.name
                        .toLowerCase()
                        .includes(searchArr) ||
                        item?.doctor?.name.toLowerCase().includes(searchArr);
                })
                .map((list, index) => {
                  return (
                    <tr key={index}>
                      <td>{(currentPage - 1) * 10 + (index + 1)}</td>
                      <td className="text-center">
                        {list?.hospital_id ? list?.hospital_id : "-"}
                      </td>
                      <td className="text-center">
                        {list?.patient_id ? list?.patient_id : "-"}
                      </td>
                      <td className="text-center">{list?.doctor?.name}</td>
                      <td className="text-center">{list?.speciality?.name}</td>
                      <td className="text-center">
                        {moment(list?.to_time).format("DD-MM-YYYY")}
                      </td>
                      <td className="text-center">
                        <button
                          className={
                            list.status == 6
                              ? "comn-btn active-deact-btn bg-greens"
                              : "comn-btn active-deact-btn bg-primary1"
                          }
                        >
                          {list?.status == 1 && "Incoming"}
                          {list?.status == 2 && "Initiated"}
                          {list?.status == 3 && "completed"}
                          {list?.status == 4 && "Acknowledged"}
                          {list?.status == 5 && "Not Connected"}

                          {list?.status == 6 && "Reschedule"}
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
          <div className="d-flex align-center page-item">
            <button className="page-link" onClick={prevPage}>
              {"<"}
            </button>
            {pages?.map((page, ind) => {
              return (
                <div className={currentPage == page ? "active" : null}>
                  <button
                    className={
                      currentPage == page ? "active page-link" : "page-link"
                    }
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </button>
                </div>
              );
            })}
            <button className="page-link" onClick={nextPage}>
              {">"}
            </button>
          </div>
        </div>
      </div>
      {/* <div className="p-3">
        <div className="ad-btn w-100 flex-ac-jb p-2">
          <Form>
            <select onChange={setListItems} name="" id="">
              <option value="10">10</option>
              <option value={adminData.length}>All</option>
            </select>
          </Form>
          <div className="flex-ac-jc">
            <Form className="search-input me-2">
              <input
                onChange={(e) => setSearchArr(e.target.value)}
                placeholder="search..."
                type="search"
                className="bg-transparent rounded-1 px-2 py-1"
              />
            </Form>
          </div>
        </div>

        <div className="p-2 bg-white rounded-2">
          <p
            className="permission-text txt-primary4 mb-1 mt-0 text-uppercase"
            for=""
          >
            Patient's
          </p>
          <table className="custom-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Patient Name</th>
                <th>Cateories</th>
                <th>Date</th>
                <th className="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((list, index) => (
                <tr key={index}>
                  <td>
                    {index == 9
                      ? last
                      : counter
                      ? `${counter}${index + 1}`
                      : index + 1}
                  </td>

                  <td>{list.name}</td>
                  <td>{list.type}</td>
                  <td>{list.date}</td>
                  <td className="text-center">
                    <button
                      className={
                        list.status == "Finished"
                          ? "comn-btn active-deact-btn bg-greens"
                          : "comn-btn active-deact-btn bg-primary1"
                      }
                    >
                      Completed
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div> */}

      {/* <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
        <button className="comn-btn bg-primary2 px-3 py-2 me-2">Reject</button>
        <Link to="/doctor">
          <button
            // onClick={() => {
            //   <Navigate to="/doctor" replace />;
            // }}
            type="button"
            className="comn-btn bg-hash2 px-3 py-2 me-2"
            data-dismiss="modal"
          >
            Back
          </button>
        </Link>
      </div> */}
    </aside>
  );
};

export default DoctorDetails;
