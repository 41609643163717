import React, { useEffect, useState } from "react";
import TopHeader from "../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, Modal } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
import reportServices from "../Services/Service/report";
let active = 3;
let items = [];
for (let number = 1; number <= 4; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>
  );
}
const HospitalReport = () => {
  const [adminData, setadminData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [searcharr, setSearchArr] = useState("");

  console.log(adminData);
  useEffect(() => {
    let temp = [...adminData];
    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter(
        (val) =>
          val?.name.toLowerCase().includes(searcharr.toLowerCase()) ||
          val?.mobile.toLowerCase().includes(searcharr.toLowerCase())
      );
      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    // console.log(event);
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const apiReportHandler = () => {
    reportServices
      .hospital_List()
      .then((res) => {
        let temp = res.data?.hospitals.reverse();
        setadminData(temp);
        console.log(res);
      })
      .catch((e) => e);
  };
  useEffect(() => {
    apiReportHandler();
  }, []);
  console.log(adminData);
  console.log("currentItems", currentItems);

  return (
    <aside>
      <article className="p-3">
        <div className="ad-btn w-100 flex-ac-jb px-0 py-2">
          <div />
          <div className="flex-ac-jc">
            <Form className="search-input me-2">
              <input
                onChange={(e) => setSearchArr(e.target.value)}
                placeholder="search..."
                type="search"
                className="bg-transparent rounded-1 px-2 py-1"
              />
            </Form>
          </div>
        </div>
        <div className="p-2 bg-white rounded-2">
          <table className="custom-table">
            <thead>
              <tr>
                <th className="text-center">S.No</th>
                <th className="text-center">Name</th>
                <th className="text-center">Mobile</th>
                <th className="text-center">Admin Commission</th>
                <th className="text-center">Appoinments</th>
                <th className="text-center">Hospital Commissions</th>
                <th className="text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.map((list, index) => (
                <tr key={index}>
                  <td>
                    {index == 9
                      ? last
                      : counter
                      ? `${counter}${index + 1}`
                      : index + 1}
                  </td>
                  <td className="text-center">{list?.name}</td>
                  <td className="text-center">{list?.mobile}</td>
                  <td className="text-center">
                    {list?.total_admin_commission}
                  </td>
                  <td className="text-center">{list?.total_appointments}</td>
                  <td className="text-center">
                    {list?.total_hospital_commission}
                  </td>
                  <td className="text-center">
                    {list?.status === 1
                      ? "Initiated"
                      : list?.status === 2
                      ? "Success"
                      : list?.status === 3 && "Failure"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </article>
    </aside>
  );
};

export default HospitalReport;
