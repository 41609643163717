import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { maleAvatar } from "../assets/img";
import HospitalCategories from "../component/HospitalCategories";
import PatientAppoinment from "../component/PatientAppoinment";
import Specialities from "../component/Specialities";
import TopHeader from "../component/TopHeader";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import { hospital } from "../Services/Service/hospital";
import swal from "sweetalert";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { validEmail } from "../Services/Constant/Constant";
import { useAddHospitalMutation } from "../redux/api/api";
import { statesData } from "../redux/api/DummyJson";

const HospitalAddcopy = () => {
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState();
  const [profilePreview, setProfilePreview] = useState();
  const [licence, setLicence] = useState();
  const [licencePreview, setLicencePreview] = useState();
  const [mui, setMui] = useState();
  const [muiPreview, setMuiPreview] = useState();
  const [inputs, setInputs] = useState();
  const [err, setErr] = useState("");

  const [hName, setHName] = useState("");
  const [aName, setAName] = useState("");
  const [uName, setUName] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [hYear, setHYear] = useState("");
  const [fee, setFee] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [Street1, setStreet1] = useState("");
  const [Street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [aNumber, setANumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [gstNo, setGSTNo] = useState("");
  const [bankName, setBankName] = useState("");

  const [hNameErr, setHNameErr] = useState(false);
  const [aNameErr, setANameErr] = useState(false);
  const [uNameErr, setUNameErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [mobileErr, setMobileErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [hYearErr, setHYearErr] = useState(false);
  const [feeErr, setFeeErr] = useState(false);
  const [addressErr, setAddressErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [Street1Err, setStreet1Err] = useState(false);
  const [Street2Err, setStreet2Err] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [stateErr, setStateErr] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [pincodeErr, setPincodeErr] = useState(false);
  const [businessNameErr, setBusinessNameErr] = useState("");
  const [aNumberErr, setANumberErr] = useState(false);
  const [ifscErr, setIfscErr] = useState(false);
  const [accountHolderErr, setAccountHolderErr] = useState(false);
  const [panNumberErr, setPanNumberErr] = useState(false);
  const [gstNoErr, setGSTNoErr] = useState(false);
  const [bankNameErr, setBankNameErr] = useState(false);
  const [hospitalImageErr, setHospitalImageErr] = useState(false);
  const [licenseErr, setLicenseErr] = useState(false);
  const [muiErr, setMuiErr] = useState(false);
  const [modalErr, setModalErr] = useState(false);
  const handlechange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  // console.log(inputs);
  // console.log(pincode);
  const notify = (error_msg) => {
    toast.error(error_msg);
  };

  const [addHospitalform] = useAddHospitalMutation();

  const addHospitalHandler = () => {
    if (
      hName?.length == 0 ||
      aName?.length == 0 ||
      uName?.length == 0 ||
      password?.length < 8 ||
      mobile?.length == 0 ||
      // email == !validEmail ||
      email?.length == 0 ||
      hYear?.length == 0 ||
      fee?.length == 0 ||
      address?.length == 0 ||
      description?.length == 0 ||
      Street1?.length == 0 ||
      Street2?.length == 0 ||
      city?.length == 0 ||
      state?.length == 0 ||
      country?.length == 0 ||
      !pincode?.length == 6 ||
      businessName?.length == 0 ||
      aNumber?.length == 0 ||
      ifsc?.length == 0 ||
      accountHolder?.length == 0 ||
      panNumber?.length == 0 ||
      gstNo?.length == 0 ||
      !profileImage ||
      !licence ||
      !mui
    ) {
      // setModalErr(true);
      // setTimeout(() => {
      //   setModalErr(false);
      // }, 2000);
      // setModalErr(true);
      setHNameErr(true);
      setANameErr(true);
      setUNameErr(true);
      setPasswordErr(true);
      setMobileErr(true);
      setEmailErr(true);
      setHYearErr(true);
      setFeeErr(true);
      setAddressErr(true);
      setDescriptionErr(true);
      setStreet1Err(true);
      setStreet2Err(true);
      setCityErr(true);
      setStateErr(true);
      setCountryErr(true);
      setPincodeErr(true);
      setBusinessNameErr(true);
      setANumberErr(true);
      setIfscErr(true);
      setAccountHolderErr(true);
      setPanNumberErr(true);
      setGSTNoErr(true);
      setHospitalImageErr(true);
      setLicenseErr(true);
      setMuiErr(true);
      console.log(";ll");
    } else {
      console.log(";lsdl");
      const formdata = new FormData();
      formdata.append("name", hName);
      formdata.append("authorized_name", aName);
      formdata.append("username", uName);
      formdata.append("password", password);
      formdata.append("mobile", parseInt(mobile));
      formdata.append("email", email);
      formdata.append("hospital_year", hYear);
      formdata.append("commission_percentage", parseInt(fee));
      formdata.append("address", address);
      formdata.append("description", description);
      formdata.append("street_1", Street1);
      formdata.append("street_2", Street2);
      formdata.append("city", city);
      formdata.append("state", state);
      formdata.append("country", country);
      formdata.append("pin_code", pincode);
      formdata.append("business_name", businessName);
      formdata.append("account_number", aNumber);
      formdata.append("ifsc_code", ifsc);
      formdata.append("account_holder_name", accountHolder);
      formdata.append("pan_no", panNumber);
      formdata.append("gst_no", gstNo);
      formdata.append("image", profileImage);
      formdata.append("licence", licence);
      formdata.append("mui", mui);
      addHospitalform(formdata)
        .unwrap()
        .then((res) => {
          console.log(res, "res");
          if (res?.status === "success") {
            console.log("success", res);
            navigate("/hospital");
            swal(res?.message, "", "success");
            console.log(res?.message, "", "success");
          } else {
            swal(res?.message, "", "error");
            console.log(res?.message, "", "error");
          }
        })
        .catch((err) => {
          console.log("error", err?.data?.message);
          swal(err?.data?.message, "", "error");
        });
    }
  };

  const addHospital = () => {
    let formdata = new FormData();
    formdata.append("name", inputs?.name);
    formdata.append("authorized_name", inputs?.authorized_name);
    formdata.append("username", inputs?.userName);
    formdata.append("password", inputs?.password);
    formdata.append("hospital_year", inputs?.hospital_year);
    formdata.append("commission_percentage", parseInt(inputs?.commission_fee));
    formdata.append("address", inputs?.address);
    formdata.append("description", inputs?.description);
    formdata.append("email", inputs?.email);
    formdata.append("mobile", parseInt(inputs?.mobile));

    formdata.append("business_name", inputs?.business_name);
    formdata.append("street_1", inputs?.street_1);
    formdata.append("street_2", inputs?.street_2);
    formdata.append("city", inputs?.city);
    formdata.append("state", inputs?.state);
    formdata.append("pin_code", inputs?.pin_code);
    formdata.append("country", inputs?.country);
    formdata.append("account_number", inputs?.account_number);
    formdata.append("ifsc_code", inputs?.ifsc_code);
    formdata.append("account_holder_name", inputs?.account_holder_name);

    formdata.append("image", profileImage);
    formdata.append("licence", licence);
    formdata.append("mui", mui);

    hospital
      .hospitalAdd(formdata)
      .then((res) => {
        swal(res.data.message, "", "success");
        navigate("/hospital");
        // console.log(res);
      })
      .catch((e) => {
        ErrorHandler(e);
        console.log("e", e);
      });
  };
  useEffect(() => {
    if (!mui) {
      setMuiPreview(undefined);
      return;
    }
    const profileUrlll = URL.createObjectURL(mui);
    setMuiPreview(profileUrlll);
  }, [mui]);

  useEffect(() => {
    if (!licence) {
      setLicencePreview(undefined);
      return;
    }
    const profileUrll = URL.createObjectURL(licence);
    setLicencePreview(profileUrll);
  }, [licence]);

  useEffect(() => {
    if (!profileImage) {
      setProfilePreview(undefined);
      return;
    }
    const profileUrl = URL.createObjectURL(profileImage);
    setProfilePreview(profileUrl);
  }, [profileImage]);

  const onClickMui = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setMui(undefined);
      return;
    }
    setMui(e.target.files[0]);
  };
  const onClickLicence = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setLicence(undefined);
      return;
    }
    setLicence(e.target.files[0]);
  };
  const onClickImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setProfileImage(undefined);
      return;
    }
    setProfileImage(e.target.files[0]);
  };

  useEffect(() => {
    // GSN NO
    if (gstNo?.length > 0) {
      let regex = new RegExp(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
      );
      // Return true if the GST_CODE
      // matched the ReGex
      if (regex.test(gstNo) == true) {
        setGSTNoErr(false);
        // console.log('true');
        // return 'true';
      } else {
        setGSTNoErr(true);
        // console.log('false');
        // return 'false';
      }
    }

    // FOR EMAIL
    if (email?.length > 0) {
      let regex = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
      if (regex.test(email) == true) {
        setEmailErr(false);
      } else {
        setEmailErr(true);
      }
    }

    // FOR IFSC
    if (ifsc?.length > 0) {
      let regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
      if (regex.test(ifsc) == true) {
        setIfscErr(false);
      } else {
        setIfscErr(true);
      }
    }

    // FOR PAN NO
    if (panNumber?.length > 0) {
      let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      if (regex.test(panNumber) == true) {
        setPanNumberErr(false);
      } else {
        setPanNumberErr(true);
      }
    }
  }, [gstNo, ifsc, panNumber, email]);

  return (
    <div className="">
      <TopHeader type="add-hospital" />
      <div>
        <Toaster position="top-right" reverseOrder={false} />
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <Form className="d-flex flex-wrap">
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Hospital Name *
                    </label>
                    <input
                      onChange={(e) => {
                        setHName(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={hName}
                      id="example-if-email"
                      name="name"
                      placeholder="Enter The Hospital Name"
                      className="bg-transparent"
                      type="text"
                    />
                    {hName?.length == 0 && hNameErr && (
                      <p className="err">Enter Hospital Name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Autorized Name *
                    </label>
                    <input
                      onChange={(e) => {
                        setAName(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={aName}
                      id="example-if-email"
                      name="authorized_name"
                      placeholder="Enter The Authorized Name"
                      className="bg-transparent"
                      type="text"
                    />
                    {aName?.length == 0 && aNameErr && (
                      <p className="err">Enter Authorized Name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      UserName *
                    </label>
                    <input
                      placeholder="Enter The Username"
                      onChange={(e) => {
                        setUName(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={uName}
                      className="bg-transparent"
                      type="text"
                    />
                    {uName?.length == 0 && uNameErr && (
                      <p className="err">Enter User Name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Password *
                    </label>
                    <input
                      placeholder="Enter The Password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={password}
                      className="bg-transparent"
                      type="text"
                    />
                    {password?.length < 8 && passwordErr && (
                      <p className="err">Enter 8 digit password</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Mobile *
                    </label>
                    <input
                      onChange={(e) => {
                        setMobile(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={mobile}
                      type="number"
                      id="example-if-email"
                      placeholder="Enter The Mobile Number"
                      className="bg-transparent"
                    />
                    {mobile?.length == 0 && mobileErr && (
                      <p className="err">Enter Mobile Number</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Email *
                    </label>
                    <input
                      onChange={(e) => {
                        setEmail(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={email}
                      type="email"
                      id="example-if-email"
                      placeholder="Enter The Email"
                      className="bg-transparent"
                    />
                    {email?.length == 0 && emailErr && (
                      <p className="err">Enter Email</p>
                    )}
                    {email?.length > 0 && emailErr && (
                      <p className="err">Enter Valid Email</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Hospital Year *
                    </label>
                    <input
                      onChange={(e) => {
                        setHYear(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={hYear}
                      type="date"
                      id="example-if-email"
                      placeholder="Enter The Hospital Year"
                      className="bg-transparent"
                    />
                    {hYear?.length == 0 && hYearErr && (
                      <p className="err">Select Year Hospital</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Commission Fee ( % ) *
                    </label>
                    <input
                      onChange={(e) => {
                        setFee(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={fee}
                      type="number"
                      name="commission_fee"
                      id="example-if-email"
                      placeholder="Enter The Commission Fee"
                      className="bg-transparent"
                    />
                    {fee?.length == 0 && feeErr && (
                      <p className="err">Enter Commission fee</p>
                    )}
                    {/* <select name="" id="" disabled>
                      <option value="Active">Active</option>
                      <option value="Deactive">Deactive</option>
                    </select> */}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Address *
                    </label>
                    <textarea
                      placeholder="Enter The Address"
                      onChange={(e) => {
                        setAddress(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={address}
                      type="text"
                      className="bg-transparent"
                      cols="30"
                      rows="10"
                    ></textarea>
                    {address?.length == 0 && addressErr && (
                      <p className="err">Enter address</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Description *
                    </label>
                    <textarea
                      placeholder="Enter The Description"
                      onChange={(e) => {
                        setDescription(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={description}
                      type="text"
                      className="bg-transparent"
                      cols="30"
                      rows="10"
                    ></textarea>
                    {description?.length == 0 && descriptionErr && (
                      <p className="err">Enter The Description</p>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                    Business Name
                    </label>
                    <input
                    value={inputs?.business_name}
                      placeholder="Legal Business Name"
                      type="text"
                      onChange={handlechange}
                      name="business_name"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                    Business Type
                    </label>
                    <input
                      placeholder="Business Type"
                      type="text"
                      onChange={handlechange}
                      name="business_type"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                    Category
                    </label>
                    <input
                      placeholder="Category"
                      type="text"
                      onChange={handlechange}
                      name="category"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                    Sub Category
                    </label>
                    <input
                      placeholder="Sub Category"
                      type="text"
                      onChange={handlechange}
                      name="subcategory"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div> */}
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Street 1
                    </label>
                    <input
                      placeholder="Enter The Street 1"
                      onChange={(e) => {
                        setStreet1(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={Street1}
                      type="text"
                      name="street_1"
                      className="bg-transparent"
                    />
                    {Street1?.length == 0 && Street1Err && (
                      <p className="err">Enter Street Name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Street 2
                    </label>
                    <input
                      placeholder="Enter The Street 2"
                      onChange={(e) => {
                        setStreet2(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={Street2}
                      type="text"
                      name="Enter The Street 2"
                      className="bg-transparent"
                    />
                    {Street2?.length == 0 && Street2Err && (
                      <p className="err">Enter Street 2 Name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      City *
                    </label>
                    <input
                      placeholder="Enter The City"
                      onChange={(e) => {
                        setCity(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={city}
                      type="text"
                      name="city"
                      className="bg-transparent"
                    />
                    {city?.length == 0 && cityErr && (
                      <p className="err">Enter City Name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      State *
                    </label>
                    {/* <input
                      placeholder="Enter The State"
                      onChange={(e) => {
                        setState(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={state}
                      type="text"
                      name="state"
                      className="bg-transparent"
                    /> */}
                    <select
                      className="bg-transparent"
                      value={state}
                      onChange={(e) => {
                        setState(e.target.value);
                      }}
                    >
                      <option hidden>Select State</option>
                      {statesData.map((item, ind) => {
                        return (
                          <option value={item?.name} key={ind}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                    {state?.length == 0 && stateErr && (
                      <p className="err">Select State</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Country *
                    </label>
                    <input
                      placeholder="Enter The Country"
                      onChange={(e) => {
                        setCountry(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={country}
                      type="text"
                      name="country"
                      className="bg-transparent"
                    />
                    {country?.length == 0 && countryErr && (
                      <p className="err">Enter Country Name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Pin Code *
                    </label>
                    <input
                      placeholder="Enter The Pin Code"
                      onChange={(e) => {
                        setPincode(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={pincode}
                      type="number"
                      name="pin_code"
                      className="bg-transparent"
                    />
                    {pincode?.length !== 6 && pincodeErr && (
                      <p className="err">Enter pincode Mustbe 6 Digit</p>
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Bank Name
                    </label>
                    <input
                      placeholder="Bank Name"
                      type="text"
                      onChange={handlechange}
                      name="bank_name"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div> */}
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Business Name *
                    </label>
                    <input
                      placeholder="Enter The Business Name"
                      onChange={(e) => {
                        setBusinessName(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={businessName}
                      type="text"
                      name="business_name"
                      className="bg-transparent"
                    />
                    {businessName?.length == 0 && businessNameErr && (
                      <p className="err">Enter Business Name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Account Number *
                    </label>
                    <input
                      placeholder="Enter The AcountNumber"
                      onChange={(e) => {
                        setANumber(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={aNumber}
                      type="text"
                      name="account_number"
                      className="bg-transparent"
                    />
                    {aNumber?.length == 0 && aNumberErr && (
                      <p className="err">Enter Account Number</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      IFSC Code *
                    </label>
                    <input
                      placeholder="Enter The IFSC Code "
                      onChange={(e) => {
                        setIfsc(e.target.value.toLocaleUpperCase());
                        console.log(e.target.value);
                      }}
                      value={ifsc}
                      type="text"
                      className="bg-transparent"
                    />
                    {ifsc?.length == 0 && ifscErr && (
                      <p className="err">Enter IFSC Code</p>
                    )}
                    {ifsc?.length > 0 && ifscErr && (
                      <p className="err">Enter Valid IFSC Code</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Account Holder Name *
                    </label>
                    <input
                      placeholder="Enter The Account Holder Name "
                      onChange={(e) => {
                        setAccountHolder(e.target.value);
                        console.log(e.target.value);
                      }}
                      value={accountHolder}
                      type="text"
                      name="account_holder_name"
                      className="bg-transparent"
                    />
                    {accountHolder?.length == 0 && accountHolderErr && (
                      <p className="err">Enter account holder name</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      PAN Number *
                    </label>
                    <input
                      placeholder="Enter The PAN Number "
                      onChange={(e) => {
                        setPanNumber(e.target.value.toLocaleUpperCase());
                      }}
                      value={panNumber}
                      type="text"
                      name="pan_number"
                      className="bg-transparent"
                    />
                    {panNumber?.length == 0 && panNumberErr && (
                      <p className="err">Enter PAN Number</p>
                    )}
                    {panNumber?.length > 0 && panNumberErr && (
                      <p className="err">Enter Valid PAN Number</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      GST NO *
                    </label>
                    <input
                      placeholder="Enter The GST Number "
                      onChange={(e) => {
                        setGSTNo(e.target.value);
                      }}
                      value={gstNo}
                      type="text"
                      name="gst_number"
                      className="bg-transparent"
                    />
                    {gstNo?.length == 0 && gstNoErr && (
                      <p className="err">Enter GST Number</p>
                    )}
                    {gstNo?.length > 0 && gstNoErr && (
                      <p className="err">Enter Valid GST Number</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group col-12 col-sm-6 col-lg-4 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Hospital Image *
                    </label>
                    <input type="file" onChange={onClickImage} />
                    {profileImage && (
                      <div className="w-90 prev-Image">
                        <img className="w-100" src={profilePreview} />
                      </div>
                    )}
                    {!profileImage && hospitalImageErr && (
                      <p className="err">Choose Hospital</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-4 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Licence Image *
                    </label>
                    <input type="file" onChange={onClickLicence} />{" "}
                    {!licence && licenseErr && (
                      <p className="err">Choose Licence Image</p>
                    )}
                    {licence && (
                      <div className="w-90 prev-Image">
                        <img className="w-100" src={licencePreview} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-4 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      MUI *
                    </label>
                    <input type="file" onChange={onClickMui} />
                    {mui && (
                      <div className="w-90 prev-Image">
                        <img className="w-100" src={muiPreview} />
                      </div>
                    )}
                    {!mui && muiErr && (
                      <p className="err">Choose Licence Image</p>
                    )}
                  </div>
                </div>
              </div>
            </Form>
            <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
              {/* <button className="comn-btn bg-hash2 px-3 py-2 me-2">
                Cancel
              </button> */}
              <button
                onClick={addHospitalHandler}
                type="submit"
                className="comn-btn bg-blues px-3 py-2 me-2"
                data-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <HospitalCategories /> */}
      {modalErr && (
        <div className="modal-err">
          <div className="in-modal-box">
            {hName?.length == 0 && hNameErr && (
              <p className="err">Hospital Name |</p>
            )}
            {aName?.length == 0 && aNameErr && (
              <p className="err"> Authorized Name |</p>
            )}
            {uName?.length == 0 && uNameErr && <p className="err">User Name</p>}
            {password?.length < 8 && passwordErr && (
              <p className="err">8 digit password |</p>
            )}
            {mobile?.length == 0 && mobileErr && (
              <p className="err">Mobile Number |</p>
            )}
            {email?.length == 0 && emailErr && (
              <p className="err">Valid Email |</p>
            )}
            {hYear?.length == 0 && hYearErr && (
              <p className="err">Select Year Hospital |</p>
            )}
            {fee?.length == 0 && feeErr && (
              <p className="err">Commission fee |</p>
            )}
            {address?.length == 0 && addressErr && (
              <p className="err">Aaddress |</p>
            )}
            {description?.length == 0 && descriptionErr && (
              <p className="err">Description |</p>
            )}
            {Street1?.length == 0 && Street1Err && (
              <p className="err">Street Name |</p>
            )}
            {Street2?.length == 0 && Street2Err && (
              <p className="err">Street 2 Name |</p>
            )}
            {state?.length == 0 && stateErr && (
              <p className="err">State Name |</p>
            )}
            {country?.length == 0 && countryErr && (
              <p className="err">Country Name |</p>
            )}
            {pincode?.length !== 6 && pincodeErr && (
              <p className="err">Enter pincode Mustbe 6 Digit |</p>
            )}
            {aNumber?.length == 0 && aNumberErr && (
              <p className="err">Account Number |</p>
            )}
            {ifsc?.length == 0 && ifscErr && <p className="err">IFSC Code</p>}
            {accountHolder?.length == 0 && accountHolderErr && (
              <p className="err">Account holder name |</p>
            )}
            {panNumber?.length == 0 && panNumberErr && (
              <p className="err">PAN Number |</p>
            )}
            {gstNo?.length == 0 && gstNoErr && (
              <p className="err">GST Number |</p>
            )}
            {!profileImage && hospitalImageErr && (
              <p className="err">Choose Hospital |</p>
            )}
            {!licence && licenseErr && (
              <p className="err">Choose Licence Image |</p>
            )}
            {!mui && muiErr && <p className="err">Choose Licence Image</p>}
          </div>
        </div>
      )}
      {/* {(hName?.length == 0 && hNameErr) ||
        (aName?.length == 0 && aNameErr) ||
        (uName?.length == 0 && uNameErr) ||
        (mobile?.length == 0 && mobileErr) ||
        (email?.length == 0 && emailErr) ||
        (hYear?.length == 0 && hYearErr) ||
        (fee?.length == 0 && feeErr) ||
        (address?.length == 0 && addressErr) ||
        (description?.length == 0 && descriptionErr) ||
        (Street1?.length == 0 && Street1Err) ||
        (Street2?.length == 0 && Street2Err) ||
        (state?.length == 0 && stateErr) ||
        (country?.length == 0 && countryErr) ||
        (pincode?.length !== 6 && pincodeErr) ||
        (aNumber?.length == 0 && aNumberErr) ||
        (ifsc?.length == 0 && ifscErr) ||
        (accountHolder?.length == 0 && accountHolderErr) ||
        (!profileImage && hospitalImageErr) ||
        (!licence && licenseErr) ||
        (!mui && muiErr && (
          <div className="modal-err">
            <div className="in-modal-box">
              {hName?.length == 0 && hNameErr && (
                <p className="err">Hospital Name</p>
              )}
              {aName?.length == 0 && aNameErr && (
                <p className="err">Authorized Name</p>
              )}
              {uName?.length == 0 && uNameErr && (
                <p className="err">User Name</p>
              )}
              {password?.length < 8 && passwordErr && (
                <p className="err">8 digit password</p>
              )}
              {mobile?.length == 0 && mobileErr && (
                <p className="err">Mobile Number</p>
              )}
              {email?.length == 0 && emailErr && (
                <p className="err">Valid Email</p>
              )}
              {hYear?.length == 0 && hYearErr && (
                <p className="err">Select Year Hospital</p>
              )}
              {fee?.length == 0 && feeErr && (
                <p className="err">Commission fee</p>
              )}
              {address?.length == 0 && addressErr && (
                <p className="err">Aaddress</p>
              )}
              {description?.length == 0 && descriptionErr && (
                <p className="err">Description</p>
              )}
              {Street1?.length == 0 && Street1Err && (
                <p className="err">Street Name</p>
              )}
              {Street2?.length == 0 && Street2Err && (
                <p className="err">Street 2 Name</p>
              )}
              {state?.length == 0 && stateErr && (
                <p className="err">State Name</p>
              )}
              {country?.length == 0 && countryErr && (
                <p className="err">Country Name</p>
              )}
              {pincode?.length !== 6 && pincodeErr && (
                <p className="err">Enter pincode Mustbe 6 Digit</p>
              )}
              {aNumber?.length == 0 && aNumberErr && (
                <p className="err">Account Number</p>
              )}
              {ifsc?.length == 0 && ifscErr && <p className="err">IFSC Code</p>}
              {accountHolder?.length == 0 && accountHolderErr && (
                <p className="err">Account holder name</p>
              )}
              {!profileImage && hospitalImageErr && (
                <p className="err">Choose Hospital</p>
              )}
              {!licence && licenseErr && (
                <p className="err">Choose Licence Image</p>
              )}
              {!mui && muiErr && <p className="err">Choose Licence Image</p>}
            </div>
          </div>
        ))} */}
    </div>
  );
};

export default HospitalAddcopy;
