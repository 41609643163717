import React, { useEffect, useState } from "react";
import TopHeader from "../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { Form, Modal } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import Loader from "react-loader";
import { hospital } from "../Services/Service/hospital";
import ReactPaginate from "react-paginate";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import swal from "sweetalert";
import usePermissions from "../Services/Storage/user";

const HospitalPage = () => {
  const { user } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [adminData, setadminData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [profilePreview, setProfilePreview] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [searcharr, setSearchArr] = useState("");

  // console.log(adminData);

  useEffect(() => {
    let temp = [...adminData];

    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.name.toLowerCase().includes(searcharr.toLowerCase())
      );

      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    console.log("adminData", adminData);
    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    // console.log(event);
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);
    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;

    setItemsPerPage(parseInt(value));
  };
  const hospitalList = () => {
    hospital
      .hospitalList()
      .then((res) => {
        // console.log(res);
        let temp = res.data?.hospitals.reverse();
        setadminData(temp);
        setLoading(false);
      })
      .catch((e) => {
        ErrorHandler(e);
      });
  };

  useEffect(() => {
    hospitalList();
  }, []);

  const changeStatus = (id) => {
    hospital
      .hospitalStatus(id)
      .then((res) => {
        hospitalList();
        swal(res.data.message, "", "success");
      })
      .catch((e) => {
        ErrorHandler(e);
      });
  };

  useEffect(() => {
    if (!profileImage) {
      setProfilePreview(undefined);
      return;
    }
    const profileUrl = URL.createObjectURL(profileImage);
    setProfilePreview(profileUrl);
  }, [profileImage]);

  const onClickImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setProfileImage(undefined);
      return;
    }
    setProfileImage(e.target.files[0]);
  };

  return (
    <aside>
      <TopHeader type="hospital" />
      <article className="p-3">
        <div className="ad-btn w-100 flex-ac-jb p-2">
          <div></div>
          {/* <Form>
            <select onChange={setListItems} name="" id="">
              <option value="10">10</option>
              <option value={adminData.length}>All</option>
            </select>
          </Form> */}
          <div className="flex-ac-jc">
            <Form className="search-input me-2">
              <input
                onChange={(e) => setSearchArr(e.target.value)}
                placeholder="search..."
                type="search"
                className="bg-transparent rounded-1 px-2 py-1"
              />
            </Form>{" "}
            {user?.includes("CAN-ADD-HOSPITALS") ? (
              <Link
                to="/hospital/add-hospital"
                className="comn-btn bg-primary1 px-3 py-2 text-decoration-none"
              >
                Add Hospital
              </Link>
            ) : null}
          </div>
        </div>
        <div className="p-2 bg-white rounded-2">
          <table className="custom-table">
            <thead>
              <tr className="text-center text-nowrap">
                <th>S.No</th>
                <th>User Name</th>
                <th>Hospital Name</th>
                <th>Phone No</th>
                {/* <th className="text-center">Ratings</th> */}
                <th className="text-center">
                  Appointment
                  <br />
                  Fee (%)
                </th>
                {user.includes("CAN-STATUS-HOSPITALS") ? (
                  <th className="text-center">Status</th>
                ) : null}

                {user.includes("CAN-EDIT-HOSPITALS") ? (
                  <th className="text-center">Action</th>
                ) : user.includes("CAN-VIEW-HOSPITALS") ? (
                  <th className="text-center">Action</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {currentItems?.map((list, index) => (
                <tr key={index} className="text-center text-nowrap">
                  <td>
                    {index == 9
                      ? last
                      : counter
                      ? `${counter}${index + 1}`
                      : index + 1}
                  </td>
                  <td>{list?.username}</td>
                  <td>{list?.name}</td>
                  <td>{list?.hospital_detail?.mobile}</td>
                  {/* <td className="text-center">{list?.rating}</td> */}
                  <td className="text-center">
                    {list?.hospital_detail?.commission_percentage}
                  </td>
                  {user?.includes("CAN-STATUS-HOSPITALS") ? (
                    <td className="text-center">
                      <button
                        onClick={() => changeStatus(list?.id)}
                        className={
                          list.status == 1
                            ? "comn-btn active-deact-btn bg-greens"
                            : "comn-btn active-deact-btn bg-primary1"
                        }
                      >
                        {list.status == 1 ? "Active" : "Deactive"}
                      </button>
                    </td>
                  ) : null}

                  {user.includes("CAN-VIEW-HOSPITALS") ||
                  user.includes("CAN-EDIT-HOSPITALS") ? (
                    <td className="flex-ac text-center">
                      {user.includes("CAN-VIEW-HOSPITALS") ? (
                        <Link
                          state={{ id: list?.id }}
                          to="/hospital/hospital-details"
                          className="comn-btn bg-yellows edit-btn-svg me-1"
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </Link>
                      ) : null}
                      {user.includes("CAN-EDIT-HOSPITALS") ? (
                        <Link
                          state={{ id: list?.id, type: "edit" }}
                          to="/hospital/hospital-edit"
                          className="comn-btn bg-blues edit-btn-svg"
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </Link>
                      ) : null}
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
        {/* <Pagination className="mt-2 custom-pagi-nation justify-content-end align-items-center">
          {items}
        </Pagination> */}
      </article>
      <Modal
        className="table-modal"
        show={addModal}
        onHide={() => setAddModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Add Hospital</h4>
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Name
            </label>
            <input
              type="Name"
              id="example-if-email"
              name="example-if-email"
              placeholder="Name"
            />
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Mobile No
            </label>
            <input
              type="number"
              id="example-if-email"
              name="example-if-email"
              placeholder="Mobile No"
            />
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Email
            </label>
            <input
              type="email"
              id="example-if-email"
              name="example-if-email"
              placeholder="Email"
            />
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              commission Fee (₹)
            </label>
            <input
              type=""
              id="example-if-email"
              name="example-if-email"
              placeholder=""
            />
          </div>
          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Year
            </label>
            <input
              type="date"
              id="example-if-email"
              name="example-if-email"
              placeholder=""
            />
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Hospital Image
            </label>
            <input type="file" onChange={onClickImage} />
            {profileImage && (
              <div className="w-90 prev-Image">
                <img className="w-100" src={profilePreview} />
              </div>
            )}
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Licence
            </label>
            <input type="file" onChange={onClickImage} />
            {profileImage && (
              <div className="w-90 prev-Image">
                <img className="w-100" src={profilePreview} />
              </div>
            )}
          </div>
          {/* <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Admin Type
            </label>
            <select name="" id="">
              <option value="Super Admin">Super Admin</option>
              <option value="Admin">Admin</option>
            </select>
          </div> */}

          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Address
            </label>
            <textarea name="" id="" cols="30" rows="10">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit
              commodi ipsa, non maxime vitae.
            </textarea>
          </div>
          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Description
            </label>
            <textarea name="" id="" cols="30" rows="10">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit
              commodi ipsa, non maxime vitae.
            </textarea>
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => setAddModal(false)}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Cancel
          </button>
          <button
            type="button"
            className="comn-btn bg-greens px-3 py-2 me-2"
            data-dismiss="modal"
          >
            Submit
          </button>
        </div>
      </Modal>
      <Loader
        loaded={!loading}
        lines={12}
        length={12}
        width={4}
        radius={25}
        corners={2}
        rotate={0}
        direction={-1}
        color="#FF5A6A"
        speed={1}
        trail={60}
        shadow={false}
        hwaccel={true}
        className="spinner"
        zIndex={2e9}
        top="50%"
        left="55%"
        scale={1.0}
        loadedClassName="loadedContent"
      />
    </aside>
  );
};

export default HospitalPage;
