import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
// import Pagination from "react-bootstrap/Pagination";
import { Link } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "./GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import RoleServices from "../Services/Service/roleServices";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import ReactPaginate from "react-paginate";
import PNotify_1 from "pnotify/dist/es/PNotify";
import swal from "sweetalert";
import usePermissions from "../Services/Storage/user";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className="mb-3">
        <Col className="d-flex align-items-center">
          Show
          <select
            className="form-control w-auto mx-2"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          entries
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between">
        <Col>
          <span className="d-flex align-items-center">
            Page{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            | Go to page:{" "}
            <input
              type="number"
              className="form-control ml-2"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const RolesPage = () => {
  const [adminData, setadminData] = useState([]);

  const { user } = usePermissions();

  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);

  const [searcharr, setSearchArr] = useState("");

  useEffect(() => {
    let temp = [...adminData];

    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;

      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.role?.toLowerCase().includes(searcharr.toLowerCase())
      );

      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;

    setItemsPerPage(parseInt(value));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },

      {
        Header: "Options",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <Link
                to="#"
                className="btn btn-icon btn-rounded btn-outline-danger  mx-1 border-0"
              >
                <i className="feather icon-trash-2" />
              </Link>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  const viewList = () => {
    RoleServices.roleList()
      .then((res) => {
        // console.log(res);
        let temp = res.data?.roles.reverse();
        setadminData(temp);
        // let role = [];
        // res.data.roles?.map((list) => {
        //   role.push(list.id);
        //   console.log(role.indexOf(list.id) + 1);
        // });
      })
      .catch((e) => {
        ErrorHandler(e);
      });
  };

  useEffect(() => {
    viewList();
  }, []);

  const statusChange = (id) => {
    RoleServices.roleStatus(id).then((res) => {
      swal(res.data.message, "", "success");
      viewList();
    });
  };

  return (
    <aside>
      <TopHeader type="role" />
      <article className="p-3">
        {/* <Table columns={columns} data={data} /> */}
        <div className="ad-btn w-100 flex-ac-jb p-2">
          <div></div>
          <div className="flex-ac-jc">
            <Form className="search-input me-2">
              <input
                onChange={(e) => setSearchArr(e.target.value)}
                placeholder="search..."
                type="search"
                className="bg-transparent rounded-1 px-2 py-1"
              />
            </Form>
            {user?.includes("CAN-ADD-ROLES") ? (
              <Link
                state={{ id: "" }}
                to="/roles/add-roles"
                className="comn-btn bg-primary1 px-3 py-2 ad-rol-a"
              >
                Add Roles
              </Link>
            ) : null}
          </div>
        </div>
        <div className="p-2 bg-white rounded-2">
          {/* <Table columns={columns} data={data} /> */}
          <table className="custom-table">
            <thead>
              <tr className="text-center">
                <th>S.No</th>
                <th>Name</th>
                {user?.includes("CAN-STATUS-ROLES") ? (
                  <th className="text-center">Status</th>
                ) : null}

                {user?.includes("CAN-EDIT-ROLES") ? (
                  <th className="text-center">Action</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((list, index) => (
                <tr key={index} className="text-center">
                  <td>
                    {index == 9
                      ? last
                      : counter
                      ? `${counter}${index + 1}`
                      : index + 1}
                  </td>
                  <td>{list.role}</td>
                  {user?.includes("CAN-STATUS-ROLES") ? (
                    <td className="text-center">
                      <button
                        onClick={() => statusChange(list.id)}
                        className={
                          list.status == 1
                            ? "comn-btn active-deact-btn bg-greens"
                            : "comn-btn active-deact-btn bg-primary1"
                        }
                      >
                        {list.status == 1 ? "active" : "InActive"}
                      </button>
                    </td>
                  ) : null}
                  {user?.includes("CAN-EDIT-ROLES") ? (
                    <td className="flex-ac text-center">
                      <Link
                        state={{ id: list.id }}
                        to="/roles/add-roles"
                        className="comn-btn bg-blues edit-btn-svg"
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </Link>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
        {/* <Pagination className="mt-2 custom-pagi-nation justify-content-end align-items-center">
          {items}
        </Pagination> */}
      </article>
    </aside>
  );
};

export default RolesPage;
