import { URL } from "../Constant/URL";
import httpClient from "../Constant/httpClient";

const hospital_List = () => {
  return httpClient.get(URL.HOSPITAL_REPORT_LIST);
};

const doctor_List = () => {
  return httpClient.get(URL.DOCTOR_REPORT_LIST);
};

const hospital_Doctotr_List = () => {
  return httpClient.get(URL.HOSPITAL_DOCTOR_REPORT);
};

const upload_Report = (data) => {
  return httpClient.post(URL.UPLOAD_REPORT, data);
};
const download_Report = (data) => {
  return httpClient.post(URL.DOWNLOAD_REPORT, data);
};

const reportServices = {
  doctor_List,
  hospital_List,
  hospital_Doctotr_List,
  upload_Report,
  download_Report,
};

export default reportServices;
