import { URL } from "../Constant/URL";
import httpClient from "../Constant/httpClient";

const hospitalList = () => {
  return httpClient.get(URL.HOSPITAL_LIST);
};

const hospitalAdd = (data) => {
  return httpClient.post(URL.HOSPITAL_LIST, data);
};

const doctorAdd = (data) => {
  return httpClient.post(URL.HOSPITAL_DOCTOR, data);
};

const doctorEdit = (id) => {
  return httpClient.get(URL.HOSPITAL_DOCTOR + "/" + id);
};

const doctorUpdate = (id, data) => {
  return httpClient.post(URL.HOSPITAL_DOCTOR + "/" + id, data);
};

const hospitalView = (id) => {
  return httpClient.get(URL.HOSPITAL_VIEW + id);
};

const hospitalUpdate = (id, data) => {
  return httpClient.post(URL.HOSPITAL_UPDATE + id, data);
};

const hospitalStatus = (id) => {
  return httpClient.get(URL.HOSPITAL_STATUS + id);
};

const hospitalDoctorList = (id) => {
  return httpClient.get(URL.HOSPITAL_DOCTOR_LIST + id);
};

const hospitalDoctors = () => {
  return httpClient.get(URL.HOSPITAL_DOCTOR);
};

const hospitalCreate = () => {
  return httpClient.get(URL.HOSPITAL_CREATE);
};

const hospitalDocStatus = (id) => {
  return httpClient.get(URL.DOCTOR_STATUS + id);
};

export const hospital = {
  doctorUpdate,
  doctorEdit,
  doctorAdd,
  hospitalDoctorList,
  hospitalCreate,
  hospitalDoctors,
  hospitalStatus,
  hospitalUpdate,
  hospitalList,
  hospitalAdd,
  hospitalView,
  hospitalDocStatus,
};
