import React, { useEffect, useLayoutEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import { maleAvatar } from "../assets/img";
import HospitalCategories from "../component/HospitalCategories";
import PatientAppoinment from "../component/PatientAppoinment";
import Specialities from "../component/Specialities";
import TopHeader from "../component/TopHeader";
import TopHeader1 from "../component/TopHeader1";
import { hospital } from "../Services/Service/hospital";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import moment from "moment";

const HViewDoctor = () => {
  const location = useLocation();
  const [inputs, setInputs] = useState();
  const [specialities, setSpecialities] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [searcharr, setSearchArr] = useState("");
  const animatedComponents = makeAnimated();
  const [selected, setSelected] = useState([]);

  console.log(adminData);

  useEffect(() => {
    let temp = [...adminData];
    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter(
        (val) =>
          val?.patient?.name.toLowerCase().includes(searcharr.toLowerCase()) ||
          val?.speciality?.name.toLowerCase().includes(searcharr.toLowerCase())
      );
      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    // console.log(event);
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };
  const apiData = () => {
    hospital
      .doctorEdit(location?.state?.id)
      .then((resp) => {
        setInputs(resp?.data?.doctor);
        setAdminData(resp?.data?.appointments);
        console.log(resp?.data);
      })
      .catch((err) => err);
  };
  const viewDoctor = () => {
    let data = {};
    let temp = [];
    let speciality_list = [];

    hospital.hospitalCreate().then((res) => {
      let temp = [];
      res?.data?.specializations?.map((list) => {
        speciality_list.push({ value: list.id, label: list.name });
      });

      setSpecialities(speciality_list);
    });

    hospital.doctorEdit(location?.state?.id).then((res) => {
      res.data?.speciality_ids?.map((list) => {
        temp.push(list);
      });

      let select_id = speciality_list.filter((val) => {
        if (temp.includes(val.value)) {
          return true;
        }
        return false;
      });
      setSelected(select_id);
    });
  };

  const handleChange = () => {};

  useEffect(() => {
    apiData();
    viewDoctor();
  }, []);

  return (
    <aside className="">
      <TopHeader1 type="view-doctor" />
      <article>
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <Form className="d-flex flex-wrap">
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Name
                    </label>
                    <input
                      onChange={handleChange}
                      value={inputs?.name}
                      disabled
                      type="text"
                      id="example-if-email"
                      name="name"
                      placeholder="Name"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      UserName
                    </label>
                    <input
                      onChange={handleChange}
                      type="text"
                      disabled
                      id="example-if-email"
                      value={inputs?.username}
                      name="username"
                      placeholder="userName"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Mobile
                    </label>
                    <input
                      onChange={handleChange}
                      type="Name"
                      disabled
                      id="example-if-email"
                      value={inputs?.detail?.mobile}
                      name="mobile"
                      placeholder="mobile"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Email
                    </label>
                    <input
                      onChange={handleChange}
                      type="email"
                      disabled
                      id="example-if-email"
                      value={inputs?.detail?.email}
                      name="email"
                      placeholder="Name"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Qualification
                    </label>
                    <input
                      value={inputs?.qualification}
                      onChange={handleChange}
                      disabled
                      type="text"
                      id="example-if-email"
                      name="qualification"
                      placeholder="Qualification"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Appointment Fee ( ₹ )
                    </label>
                    <input
                      value={inputs?.total_fees}
                      onChange={handleChange}
                      disabled
                      type="text"
                      id="example-if-email"
                      name="total_fees"
                      placeholder="Name"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Duration ( min )
                    </label>
                    <input
                      value={inputs?.interval_mins}
                      onChange={handleChange}
                      disabled
                      type="number"
                      id="example-if-email"
                      name="interval_min"
                      placeholder="Name"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Ratings
                    </label>
                    <input
                      value={inputs?.rating}
                      onChange={handleChange}
                      disabled
                      type="number"
                      id="example-if-email"
                      name="rating"
                      placeholder="Name"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Experience ( Year )
                    </label>
                    <input
                      value={inputs?.experience}
                      onChange={handleChange}
                      disabled
                      type="text"
                      id="example-if-email"
                      name="experience"
                      placeholder="Name"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Age
                    </label>
                    <input
                      value={inputs?.detail?.age}
                      onChange={handleChange}
                      disabled
                      type="number"
                      id="example-if-email"
                      name="age"
                      placeholder="Age"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-12 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Gender
                    </label>
                    <input
                      value={inputs?.detail?.gender}
                      onChange={handleChange}
                      disabled
                      type="text"
                      id="example-if-email"
                      name="gender"
                      placeholder="Gender"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Description
                    </label>
                    <p className="address-request textarea-height">
                      {inputs?.detail?.description}
                    </p>
                  </div>
                </div>
              </div>
              <div className="form-group col-12 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Specialities
                    </label>
                    <Select
                      // onChange={(e) => setSelected(e)}
                      // closeMenuOnSelect={false}
                      // components={animatedComponents}
                      value={selected}
                      disabled={true}
                      defaultValue={[selected]}
                      isMulti
                      options={specialities}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-4 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Profile Image
                    </label>
                    {/* <input type="file" onChange={onClickImage} disabled /> */}
                    {/* {profilePreview && (
                      <div className="w-90 prev-Image">
                        <img className="w-100" src={profilePreview} />
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="p-3">
          <div className="ad-btn w-100 flex-ac-jb p-2">
            <Form>
              {/* <select onChange={setListItems} name="" id="">
                <option value="10">10</option>
                <option value={adminData.length}>All</option>
              </select> */}
            </Form>
            <div className="flex-ac-jc">
              <Form className="search-input me-2">
                <input
                  onChange={(e) => setSearchArr(e.target.value)}
                  placeholder="search..."
                  type="search"
                  className="bg-transparent rounded-1 px-2 py-1"
                />
              </Form>
            </div>
          </div>
          <div className="p-2 bg-white rounded-2">
            <table className="custom-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Patient Name</th>
                  <th>Cateories</th>
                  <th>Date</th>
                  <th className="text-center">Status</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((list, index) => (
                  <tr key={index}>
                    <td>
                      {index == 9
                        ? last
                        : counter
                        ? `${counter}${index + 1}`
                        : index + 1}
                    </td>
                    <td>{list?.patient?.name}</td>

                    <td>{list?.speciality?.name}</td>
                    <td>{moment(list?.from_time).format("DD-MM-YYYY")}</td>
                    <td className="text-center">
                      <button
                        className={
                          list.status == "Finished"
                            ? "comn-btn active-deact-btn bg-greens"
                            : "comn-btn active-deact-btn bg-primary1"
                        }
                      >
                        Completed
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
            <ReactPaginate
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </article>
    </aside>
  );
};

export default HViewDoctor;
