import {
  faBell,
  faCalendar,
  faHospital,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBed,
  faCoins,
  faUserDoctor,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import swal from "sweetalert";
import { dashbaordIcon } from "../assets/img";
import TopHeader from "../component/TopHeader";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import { dashBoardServices } from "../Services/Service/dashBoardServices";

const Home = () => {
  const [list, setList] = useState([]);
  const [fee, setFee] = useState("");

  const changeFee = () => {
    dashBoardServices
      .changeFees({ percentage: fee })
      .then((res) => {
        getData();
        swal(res.data.message, "", "success");
      })
      .catch((e) => ErrorHandler(e));
  };

  const getData = () => {
    dashBoardServices
      .adminDashBoard()
      .then((res) => {
        setList(res.data);
        setFee(res.data?.setting?.value);
        console.log("res", res.data?.setting?.value);
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <aside>
      <TopHeader type="dashboard" />
      <article className="p-2">
        <Col className="d-flex flex-wrap">
          <Col xs={6} sm={4} lg={3} className="p-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-danger-gradient">
                  <FontAwesomeIcon icon={faBed} />
                </button>
                <h6 className="text-start">Total</h6>
                <h5 className="text-start">Patients</h5>
              </div>
              <h3 className="text-end text-">{list?.patients}</h3>
            </div>
          </Col>
          <Col xs={6} sm={4} lg={3} className="p-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-primary-gradient">
                  <FontAwesomeIcon icon={faHospital} />
                </button>
                <h6 className="text-start">Total</h6>
                <h5 className="text-start">Hospital</h5>
              </div>
              <h3 className="text-end text-">{list?.hospitals}</h3>
            </div>
          </Col>
          <Col xs={6} sm={4} lg={3} className="p-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-secondary-gradient">
                  <FontAwesomeIcon icon={faUserDoctor} />
                </button>
                <h6 className="text-start">Hospital</h6>
                <h5 className="text-start">Doctor</h5>
              </div>
              <h3 className="text-end text-">{list?.doctors}</h3>
            </div>
          </Col>
          <Col xs={6} sm={4} lg={3} className="p-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-secondary-gradient">
                  <FontAwesomeIcon icon={faUserDoctor} />
                </button>
                <h6 className="text-start">Individual</h6>
                <h5 className="text-start">Doctor</h5>
              </div>
              <h3 className="text-end text-">{list?.individual_doctors}</h3>
            </div>
          </Col>
          <Col xs={6} sm={4} lg={3} className="p-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-success-gradient">
                  <FontAwesomeIcon icon={faCalendar} />
                </button>
                <h6 className="text-start">Total</h6>
                <h5 className="text-start">Appointment</h5>
              </div>
              <h3 className="text-end text-">{list?.appointments}</h3>
            </div>
          </Col>
          <Col xs={6} sm={4} lg={3} className="p-2">
            <div className="dash-box px-3 py-2">
              <div className="">
                <button className="bg-prim-gradient">
                  <FontAwesomeIcon icon={faCoins} />
                </button>
                <h6 className="text-start">Total</h6>
                <h5 className="text-start">Revenue</h5>
              </div>
              <h3 className="text-end text-">{list?.total_revenue}</h3>
            </div>
          </Col>
        </Col>
        <Col className="py-2">
          <Col xs={12} md={6} lg={6} className="px-2">
            <div className="bg-background1 form-group col-12 ps-0 rounded-2 px-2 py-2">
              <div className="form-check ps-0 ">
                <div className="px-2 col-sm-12 modal-label-input">
                  <label className="text-primary-dark" for="">
                    Individual Doctor (%) :
                  </label>
                  <input
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    type="number"
                    id="example-if-email"
                    name="example-if-email"
                    placeholder="Enter Commission Fee"
                    value={fee}
                    onChange={(e) => setFee(e.target.value)}
                    className="bg-transparent"
                  />
                </div>
              </div>
              <div className="col-xs-12 d-flex justify-content-end px-2 mb-2">
                <button
                  onClick={changeFee}
                  className="comn-btn bg-primary1 px-3 py-2 me-0">
                  Change Fee
                </button>
              </div>
            </div>
          </Col>
        </Col>
      </article>
    </aside>
  );
};

export default Home;
