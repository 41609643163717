import { URL } from "../Constant/URL";
import httpClient from "../Constant/httpClient";

const adminDashBoard = () => {
  return httpClient.get(URL.DASHBOARD);
};

const hospitalDashBoard = () => {
  return httpClient.get(URL.DASHBOARD);
};

const changeFees = (data) => {
  return httpClient.post(URL.CHANGE_FEES, data);
};

export const dashBoardServices = {
  adminDashBoard,
  hospitalDashBoard,
  changeFees,
  adminDashBoard,
};
