import React, { useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import {
  adminIcon,
  AppointmentIcon,
  BannerIcon,
  categoryIcon,
  dashbaordIcon,
  doctorIcon,
  FaqIcon,
  HelpIcon,
  hospitalIcon,
  Logout,
  NotifIcon,
  patientIcon,
  PrivacyIcon,
  RequestIcon,
  roleIcon,
  TermsCondiIcon,
} from "../assets/img";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import { LoginServices } from "../Services/Service/login";
import usePermissions from "../Services/Storage/user";
import useToken from "../Services/Storage/useToken";

const Navbar = () => {
  const locations = useLocation();
  // console.log(locations);
  const { user } = usePermissions();
  // console.log(user);
  const navigate = useNavigate();

  const { pathname } = locations;
  const splitlocations = pathname.split("/");
  // console.log(splitlocations);
  const { token, setToken } = useToken();

  useEffect(() => {
    if (!token) {
      // <Navigate to="/signin" replace={true} />;
      // locations.reload();
      // <Navigate replace to={"/signin"} />;
      // console.log("clicked");
    }
  }, [locations]);

  const logoutAccount = () => {
    // LoginServices.logout()
    //   .then((res) => {
    //     swal(res.data.message, "", "success");

    // setTimeout(() => {
    navigate("/signin");
    localStorage.clear();

    // locations.reload();
    // }, 1000);
    // })
    // .catch((e) => ErrorHandler(e));

    window.location.href = "/";
  };
  return (
    <nav>
      <ul>
        <li>
          <Link
            type="dashboard"
            className={
              splitlocations[1] === "" ? "menu-list active" : "menu-list"
            }
            to="/"
          >
            <img className="menu-icon" src={dashbaordIcon} />
            Dashboard
          </Link>
        </li>
        {/* {user?.includes("CAN-LIST-ADMINS") ? ( */}
        <li>
          <Link
            className={
              splitlocations[1] === "admin" ? "menu-list active" : "menu-list"
            }
            to="/admin"
          >
            <img className="menu-icon" src={adminIcon} />
            Admins
          </Link>
        </li>
        {/* ) : null} */}
        {/* {user?.includes("CAN-LIST-ROLES") ? ( */}
        <li>
          <Link
            className={
              splitlocations[1] === "roles" ? "menu-list active" : "menu-list"
            }
            to="/roles"
          >
            <img className="menu-icon" src={roleIcon} />
            Roles
          </Link>
        </li>
        {/* )  : null} */}
        {/* { user?.includes("CAN-LIST-PATIENTS") ? ( */}
        <li>
          <Link
            className={
              splitlocations[1] === "patient" ? "menu-list active" : "menu-list"
            }
            to="/patient"
          >
            <img className="menu-icon" src={patientIcon} />
            Pateints
          </Link>
        </li>
        {/* ) : null} */}
        {/* {user?.includes("CAN-LIST-DOCTORS") ? ( */}
        <li>
          <Link
            className={
              splitlocations[1] === "doctor" ? "menu-list active" : "menu-list"
            }
            to="/doctor"
          >
            <img className="menu-icon" src={doctorIcon} />
            Doctors
          </Link>
        </li>
        {/* ) : null} */}
        {/* {user?.includes("CAN-LIST-HOSPITALS") ? ( */}
        <li>
          <Link
            className={
              splitlocations[1] === "hospital"
                ? "menu-list active"
                : "menu-list"
            }
            to="/hospital"
          >
            <img className="menu-icon" src={hospitalIcon} />
            Hospitals
          </Link>
        </li>
        {/* )  : null} */}
        {/* { user?.includes("CAN-LIST-SPECIALITIES") ? ( */}
        <li>
          <Link
            className={
              splitlocations[1] === "categories"
                ? "menu-list active"
                : "menu-list"
            }
            to="/categories"
          >
            <img className="menu-icon" src={categoryIcon} />
            Speciality
          </Link>
        </li>
        {/* ) : null} */}
        {/* { user?.includes("CAN-LIST-BANNERS") ? ( */}
        <li>
          <Link
            className={
              splitlocations[1] === "banners" ? "menu-list active" : "menu-list"
            }
            to="/banners"
          >
            <img className="menu-icon" src={BannerIcon} />
            Banners
          </Link>
        </li>
        {/* ) : null} */}
        {/* { user?.includes("CAN-LIST-BANNERS") ? ( */}
        <li>
          <Link
            className={
              splitlocations[1] === "blog" ? "menu-list active" : "menu-list"
            }
            to="/blog"
          >
            <img className="menu-icon" src={BannerIcon} />
            Blog
          </Link>
        </li>
        {/* ) : null} */}
        {/* { user?.includes("CAN-LIST-APPOINTMENTS") ? ( */}
        <li>
          <Link
            className={
              splitlocations[1] === "appointment"
                ? "menu-list active"
                : "menu-list"
            }
            to="/appointment"
          >
            <img className="menu-icon" src={AppointmentIcon} />
            Appoinments
          </Link>
        </li>
        {/* ) : null} */}
        {/* { user?.includes("CAN-LIST-REPORTS") ? ( */}
        <li>
          <Link
            className={
              splitlocations[1] === "report" ? "menu-list active" : "menu-list"
            }
            to="/report"
          >
            <img className="menu-icon" src={RequestIcon} />
            Report
          </Link>
        </li>
        {/* ) : null} */}
        {/* <li>
          <Link
            className={
              splitlocations[1] === "notification"
                ? "menu-list active"
                : "menu-list"
            }
            to="/notication"
          >
            <img className="menu-icon" src={NotifIcon} />
            Notification
          </Link>
        </li> */}
        {/* { user?.includes("CAN-LIST-FAQS") ? ( */}
        <li>
          <Link
            className={
              splitlocations[1] === "faq" ? "menu-list active" : "menu-list"
            }
            to="/faq"
          >
            <img className="menu-icon" src={FaqIcon} />
            FAQ
          </Link>
        </li>
        {/* ) : null} */}
        {/* { user?.includes("CAN-LIST-HELP AND SUPPORTS") ? ( */}
        <li>
          <Link
            className={
              splitlocations[1] === "help-support"
                ? "menu-list active"
                : "menu-list"
            }
            to="/help-support"
          >
            <img className="menu-icon" src={HelpIcon} />
            Help & Support
          </Link>
        </li>
        {/* ) : null} */}
        <li>
          <Link onClick={logoutAccount} className="menu-list">
            <img className="menu-icon" src={Logout} />
            Logout
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
