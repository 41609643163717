import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TopHeader from "../component/TopHeader";
import { appointment } from "../Services/Service/appoinment";
import Loader from "react-loader";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import AppointmentTable from "../component/AppointmentTable";
import AppointmentHistoryTable from "../component/AppointmentHistoryTable";
import AppointmentRequest from "../component/AppointmentRequest";

const AppoinmentPage = () => {
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [requestData, setRequestData] = useState([]);
  const [upComingData, setUpComingData] = useState([]);
  const [apiReload, setApiReload] = useState(false);
  const filterHistory = historyData?.filter((e) => e.status == 4);
  const apiData = () => {
    appointment
      .appointmentHistory()
      .then((res) => {
        // console.log(res?.data?.appointments);
        let temp = [];
        res?.data?.appointments.map((item, ind) => {
          if (item?.status == 4) {
            temp.push(item);
          } else if (item?.status == 3) {
            temp.push(item);
          }
        });

        setHistoryData(temp);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
    appointment
      .rescheduleHistory()
      .then((res) => {
        // console.log(res?.data?.appointments);
        setRequestData(res?.data?.appointments);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
    appointment
      .upcomingsHistory()
      .then((res) => {
        // console.log(res?.data?.appointments);
        setUpComingData(res?.data?.appointments);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };
  // console.log(historyData);
  useEffect(() => {
    apiData();
  }, []);

  const apiReloadHandler = () => {
    if (apiReload == true) {
      apiData();
      setApiReload(false);
    }
  };

  // console.log("filterHistory", filterHistory);

  return (
    <aside>
      <TopHeader type="appointment" />
      <Tabs
        defaultActiveKey="history"
        id="fill-tab-example"
        className="tab-appoinment"
        fill
      >
        <Tab eventKey="history" title="History">
          <AppointmentHistoryTable adminData={historyData} />
        </Tab>
        <Tab eventKey="request" title="Request">
          <AppointmentRequest
            adminData={requestData}
            reloadBtn={apiReloadHandler()}
            setReload={setApiReload}
          />
        </Tab>
        <Tab eventKey="upcomming" title="Upcoming" className="txt-primary2">
          <AppointmentTable adminData={upComingData} />
        </Tab>
      </Tabs>
      <Loader
        loaded={!loading}
        lines={12}
        length={12}
        width={4}
        radius={25}
        corners={2}
        rotate={0}
        direction={-1}
        color="#FF5A6A"
        speed={1}
        trail={60}
        shadow={false}
        hwaccel={true}
        className="spinner"
        zIndex={2e9}
        top="50%"
        left="55%"
        scale={1.0}
        loadedClassName="loadedContent"
      />
    </aside>
  );
};

export default AppoinmentPage;
