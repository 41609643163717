import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, URL } from "./constants";
import { getToken } from "../store/userTocken";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async (headers) => {
      const token = getToken();
      // headers.set("Authorization", "Bearer " + localStorage.getItem("TOKEN"));
      headers.set("Authorization", "Bearer " + token);
      // headers.set("Content-Type", "multipart/form-data");
      // headers.set("Content-Type", "application/json");
      // headers.set("Accept", "application/json");
    },
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: [],
  endpoints: (builder) => ({
    // LOGIN
    login: builder.mutation({
      query: (payload) => ({
        url: URL.LOGIN,
        body: payload,
        method: "POST",
      }),
    }),

    // CHANGE PASSWORD
    changePassword: builder.mutation({
      query: (payload) => ({
        url: URL.CHANGE_PASSWORD,
        body: payload,
        method: "POST",
      }),
    }),

    // LOGOUT
    logout: builder.query({
      query: () => URL.LOGOUT,
    }),

    //Add Role
    addrole: builder.mutation({
      query: (payload) => ({
        url: URL.ROLE_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    //Role Update
    roleUpdate: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.ROLE_UPDATE + id,
        method: "POST",
        body: payload,
      }),
    }),

    //Role Edit
    roleEdit: builder.query({
      query: (id) => URL.ROLE_EDIT + id + "/" + "edit",
    }),

    //Role Create
    roleCreate: builder.query({
      query: () => URL.ROLE_CREATE,
    }),

    //Role status
    roleStatus: builder.query({
      query: (id) => URL.ROLE_CREATE + id,
    }),

    //HOSPITAL ADD
    addHospital: builder.mutation({
      query: (payload) => ({
        url: URL.HOSPITAL_ADD,
        method: "POST",
        body: payload,
      }),
    }),

    //HOSPITAL LIST
    listHospital: builder.query({
      query: () => URL.HOSPITAL_LIST,
    }),

    //HOSPITAL View
    hospitalView: builder.query({
      query: (id) => URL.HOSPITAL_VIEW + id,
    }),

    //HOSPITAL STATUS
    hospitalStatus: builder.query({
      query: (id) => URL.HOSPITAL_STATUS + id,
    }),

    // HOSPITAL UPDATE
    hospitalUpdate: builder.mutation({
      query: ({ id, payload }) => ({
        url: URL.HOSPITAL_UPDATE + id,
        method: "POST",
        body: payload,
      }),
    }),

    // HOSPITAL Add Docter
    hospitalAddDocter: builder.mutation({
      query: (payload) => ({
        url: URL.HOSPITAL_DOCTOR,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useAddHospitalMutation,
  useLazyHospitalViewQuery,
  useLoginMutation,
  useChangePasswordMutation,
  useLazyLogoutQuery,
  useAddroleMutation,
  useRoleUpdateMutation,
  useLazyRoleEditQuery,
  useLazyRoleCreateQuery,
  useLazyRoleStatusQuery,
  useHospitalUpdateMutation,
  useListHospitalQuery,
  useLazyHospitalStatusQuery,
  useHospitalAddDocterMutation,
} = api;
