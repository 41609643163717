import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
let active = 3;
let items = [];
for (let number = 1; number <= 4; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>
  );
}
const data = [
  {
    id: 1,
    name: "Ramach",
    mobile: "8987898789",
    type: "Cardiology",
    doctor: "Kiruyhio",
    status: "Upcoming",
    date: "12-09-2022",
  },
  {
    id: 2,
    name: "Akiul",
    mobile: "9098767809",
    type: "Oncology",
    doctor: "paunvie",
    status: "Finished",
    date: "12-09-2022",
  },
  {
    id: 3,
    name: "Aloi Ramach",
    mobile: "8987898789",
    type: "Brain Stroke",
    doctor: "Loinmnet",
    status: "Finished",
    date: "12-09-2022",
  },
  {
    id: 4,
    name: "kaiuo Loip",
    mobile: "9098767809",
    type: "Orthopedics",
    doctor: "Riounmn",
    status: "Finished",
    date: "12-09-2022",
  },
];

const Specialities = () => {
  const [adminData, setadminData] = useState(data);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [activeStatus, setActiveStatus] = useState(data.status);
  return (
    <div className="p-3">
      <div className="ad-btn w-100 flex-ac-jb p-2">
        <Form>
          <select name="" id="">
            <option value="10">10</option>
            <option value="20">20</option>
          </select>
        </Form>
        <div className="flex-ac-jc">
          <Form className="search-input me-2">
            <input
              placeholder="search..."
              type="search"
              className="bg-transparent rounded-1 px-2 py-1"
            />
          </Form>
          <button
            onClick={() => setAddModal(true)}
            className="comn-btn bg-primary1 px-3 py-2"
          >
            Add Specialities
          </button>
        </div>
      </div>
      <div className="p-3 bg-white rounded-2">
        <p
          className="permission-text txt-primary4 mb-1 mt-2 text-uppercase"
          for=""
        >
          Our Categories
        </p>
        <table className="custom-table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Doctor</th>
              <th>Cateories</th>
              <th>Date</th>
              <th className="text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            {adminData.map((list, index) => (
              <tr key={index}>
                <td>{list.id}</td>
                <td>{list.name}</td>
                <td>{list.doctor}</td>
                <td>{list.type}</td>
                <td>{list.date}</td>
                <td className="text-center">
                  <button
                    className={
                      list.status == "Finished"
                        ? "comn-btn active-deact-btn bg-greens"
                        : "comn-btn active-deact-btn bg-primary1"
                    }
                  >
                    {list.status}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination className="mt-2 custom-pagi-nation justify-content-end align-items-center">
        {items}
      </Pagination>
    </div>
  );
};

export default Specialities;
