import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { bannerList } from "../assets/img";
import { faqServices } from "../Services/Service/faqService";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import toast, { Toaster } from "react-hot-toast";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import usePermissions from "../Services/Storage/user";

const Faqpage = () => {
  const { user } = usePermissions();
  const [adminData, setadminData] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [banner, setBanner] = useState();
  const [bannerPreview, setBannerPreview] = useState();
  const [question, setQuestion] = useState("");
  const [description, setDescription] = useState("");
  const [view, setView] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [viewId, setViewId] = useState();

  const [searcharr, setSearchArr] = useState("");

  const notify = (error_msg) => {
    toast.error(error_msg);
  };

  useEffect(() => {
    let temp = [...adminData];

    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;

      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.question.toLowerCase().includes(searcharr.toLowerCase())
      );

      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;

    setItemsPerPage(parseInt(value));
  };

  const viewList = () => {
    faqServices.faqList().then((res) => {
      let temp = res.data?.faqs.reverse();
      setadminData(temp);
      // console.log(res);
    });
  };

  useEffect(() => {
    viewList();
  }, []);

  const addFaq = () => {
    let formdata = new FormData();

    formdata.append("question", question);
    formdata.append("answer", description);

    if (view) {
      formdata.append("_method", "PUT");
    }

    if (view) {
      if (question && description) {
        faqServices
          .editfaq(viewId, formdata)
          .then((res) => {
            setAddModal(false);
            setUpdate(false);
            viewList();
            swal(res.data.message, "", "success");
          })
          .catch((e) => ErrorHandler(e));
      } else if (!question) {
        notify("Please Enter Question");
      } else if (!description) {
        notify("Please Enter Description");
      }
    } else {
      if (question && description) {
        faqServices
          .addfaq(formdata)
          .then((res) => {
            // console.log(res);
            viewList();
            setAddModal(false);
            swal(res.data.message, "", "success");
          })
          .catch((e) => ErrorHandler(e));
      } else if (!question) {
        notify("Please Enter Question");
      } else if (!description) {
        notify("Please Enter Description");
      }
    }
  };

  const changeStatus = (id) => {
    faqServices.statusfaq(id).then((res) => {
      viewList();
      swal(res.data.message, "", "success");
    });
  };

  const editFaq = (id) => {
    setViewId(id);
    setView(true);
    faqServices.viewfaq(id).then((res) => {
      setQuestion(res.data.faq.question);
      setDescription(res.data.faq.answer);
      setAddModal(true);
      setUpdate(true);
    });
  };

  useEffect(() => {
    if (!banner) {
      setBannerPreview(undefined);
      return;
    }
    const profileUrl = URL.createObjectURL(banner);
    setBannerPreview(profileUrl);
  }, [banner]);

  const onClickImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setBanner(undefined);
      return;
    }
    setBanner(e.target.files[0]);
  };
  return (
    <aside>
      <TopHeader type="faq" />
      <article className="p-3">
        <Toaster position="top-right" reverseOrder={false} />

        <div className="ad-btn w-100 flex-ac-jb p-2">
          <div></div>
          <div className="flex-ac-jc">
            <Form className="search-input me-2">
              <input
                onChange={(e) => setSearchArr(e.target.value)}
                placeholder="search..."
                type="search"
                className="bg-transparent rounded-1 px-2 py-1"
              />
            </Form>
            {user?.includes("CAN-ADD-FAQS") ? (
              <button
                onClick={() => {
                  setView(false);
                  setDescription("");
                  setQuestion("");
                  setAddModal(true);
                }}
                className="comn-btn bg-primary1 px-3 py-2"
              >
                Add Faq
              </button>
            ) : null}
          </div>
        </div>
        <div className="p-2 bg-white rounded-2">
          <table className="custom-table">
            <thead>
              <tr className="text-center">
                <th>S.No</th>
                <th>Question</th>
                <th>Description</th>
                {user?.includes("CAN-STATUS-FAQS") ? (
                  <th className="text-center">Status</th>
                ) : null}
                {user?.includes("CAN-EDIT-FAQS") ? (
                  <th className="text-center">Action</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((list, index) => (
                <tr key={index} className="text-center">
                  <td>{index + 1}</td>
                  <td>{list.question}</td>
                  <td>{list.answer}</td>
                  {user?.includes("CAN-STATUS-FAQS") ? (
                    <td className="text-center">
                      <button
                        onClick={() => changeStatus(list.id)}
                        className={
                          list.status == 1
                            ? "comn-btn active-deact-btn bg-greens"
                            : "comn-btn active-deact-btn bg-primary1"
                        }
                      >
                        {list.status == 1 ? "Active" : "Deactive"}
                      </button>
                    </td>
                  ) : null}
                  {user?.includes("CAN-EDIT-FAQS") ? (
                    <td className="flex-ac text-center">
                      <button
                        onClick={() => editFaq(list.id)}
                        className="comn-btn bg-blues edit-btn-svg"
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </button>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <Pagination className="mt-2 custom-pagi-nation justify-content-end align-items-center">
          {items}
        </Pagination> */}
        <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
        <Modal
          className="table-modal"
          show={addModal}
          onHide={() => setAddModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <div className="px-2 py-2 col-12 d-flex flex-wrap">
            <div className="px-1 col-sm-12 modal-label-input">
              <h4>{update ? "Edit Faq" : "Add Faq"}</h4>
            </div>
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Question *
              </label>
              <input
                onChange={(e) => setQuestion(e.target.value)}
                value={question}
                type="Name"
                id="example-if-email"
                name="example-if-email"
                placeholder="Enter The Question"
              />
            </div>
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Description *
              </label>
              <textarea
                placeholder="Enter The Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                cols="30"
                rows="10"
              />
            </div>
          </div>
          <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
            <button
              onClick={() => {
                setAddModal(false);
                setUpdate(false);
              }}
              className="comn-btn bg-hash2 px-3 py-2 me-2"
            >
              Cancel
            </button>
            <button
              onClick={addFaq}
              type="button"
              className="comn-btn bg-greens px-3 py-2 me-2"
              data-dismiss="modal"
            >
              Submit
            </button>
          </div>
        </Modal>
        {/* <Modal
          className="table-modal"
          show={editModal}
          onHide={() => setEditModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <div className="px-2 py-2 col-12 d-flex flex-wrap">
            <div className="px-1 col-sm-12 modal-label-input">
              <h4>Add Faq</h4>
            </div>
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Name
              </label>
              <input
                type="Name"
                id="example-if-email"
                name="example-if-email"
                placeholder="Name"
              />
            </div>
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Description
              </label>
              <textarea name="" id="" cols="30" rows="10">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Laborum id recusandae ut officiis dolorum voluptates,
                necessitatibus at perferendis, delectus eveniet porro beatae
                excepturi vitae rerum vel temporibus fugit optio soluta.
              </textarea>
            </div>
          </div>
          <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
            <button
              onClick={() => setEditModal(false)}
              className="comn-btn bg-hash2 px-3 py-2 me-2"
            >
              Cancel
            </button>
            <button
              type="button"
              className="comn-btn bg-greens px-3 py-2 me-2"
              data-dismiss="modal"
            >
              Submit
            </button>
          </div>
        </Modal> */}
      </article>
    </aside>
  );
};

export default Faqpage;
