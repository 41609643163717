import { URL } from "../Constant/URL";
import httpClient from "../Constant/httpClient";

const patientList = () => {
  return httpClient.get(URL.PATIENT + "list");
};

const patientView = (id) => {
  return httpClient.get(URL.PATIENT + "view" + "/" + id);
};

const patienStatus = (id) => {
  return httpClient.get(URL.PATIENT + "status" + "/" + id);
};

const Hpatient_List = () => {
  return httpClient.get(URL.PATIENT_LIST);
};

const patientService = {
  patientList,
  patientView,
  patienStatus,
  Hpatient_List,
};

export default patientService;
