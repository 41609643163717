import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { bannerList } from "../assets/img";
import { supportServices } from "../Services/Service/supportService";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import ErrorHandler from "../Services/Constant/ErrorHandler";
let active = 3;
let items = [];
for (let number = 1; number <= 4; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>
  );
}

const HHelpSupportPage = () => {
  const [adminData, setadminData] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [banner, setBanner] = useState();
  const [bannerPreview, setBannerPreview] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);

  const [searcharr, setSearchArr] = useState("");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    let temp = [...adminData];

    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;

      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.name.toLowerCase().includes(searcharr.toLowerCase())
      );

      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    console.log(event);
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;

    setItemsPerPage(parseInt(value));
  };

  const supportList = () => {
    supportServices
      .hospitalSupportList()
      .then((res) => {
        setadminData(res.data.supports);
        // console.log(res.data.supports)
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    supportList();
  }, []);

  const viewSupport = (id) => {
    supportServices.supportList(id).then((res) => {
      // setadminData()
      setName(res.data);
      setAddModal(true);
    });
  };

  const replyMessage = () => {
    supportServices
      .raiseSupport({ description: message })
      .then((res) => {
        swal(res.data.message, "", "success");
        supportList();
        setAddModal(false);
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    if (!banner) {
      setBannerPreview(undefined);
      return;
    }
    const profileUrl = URL.createObjectURL(banner);
    setBannerPreview(profileUrl);
  }, [banner]);

  const onClickImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setBanner(undefined);
      return;
    }
    setBanner(e.target.files[0]);
  };
  return (
    <aside>
      <TopHeader type="help-support" />
      <article className="p-3">
        <div className="ad-btn w-100 flex-ac-jb p-2">
          {/* <Form>
            <select onChange={setListItems} name="" id="">
              <option value="10">10</option>
              <option value={adminData.length}>All</option>
            </select>
          </Form> */}
          <div className="flex-ac-jc">
            <Form className="search-input me-2">
              <input
                onChange={(e) => setSearchArr(e.target.value)}
                placeholder="search..."
                type="search"
                className="bg-transparent rounded-1 px-2 py-1"
              />
            </Form>
            <button
              onClick={() => {
                setAddModal(true);
                setMessage("");
              }}
              className="comn-btn bg-primary1 px-3 py-2"
            >
              Support
            </button>
          </div>
        </div>
        <div className="p-2 bg-white rounded-2">
          <table className="custom-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Query</th>
                <th>Message</th>

                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((list, index) => (
                <tr key={index}>
                  <td>
                    {index == 9
                      ? last
                      : counter
                      ? `${counter}${index + 1}`
                      : index + 1}
                  </td>

                  <td>{list.description}</td>
                  <td>
                    {list?.reply_message
                      ? list?.reply_message
                      : "NOT yet Replied"}
                  </td>

                  <td className="flex-ac text-center">
                    <button
                      onClick={() => setAddModal(true)}
                      className="comn-btn bg-yellows edit-btn-svg me-2"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
        {/* <Pagination className="mt-2 custom-pagi-nation justify-content-end align-items-center">
          {items}
        </Pagination> */}
        <Modal
          className="table-modal"
          show={addModal}
          onHide={() => setAddModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <div className="px-2 py-2 col-12 d-flex flex-wrap">
            <div className="px-1 col-sm-12 modal-label-input">
              <h4>Raise Support</h4>
            </div>
            {/* <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Name
              </label>
              <input
                type="text"
                id="example-if-email"
                name="example-if-email"
                placeholder="Name"         
                className="bg-transparent"
              />
            </div> */}
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Queries
              </label>
              <textarea
                value={message}
                placeholder="Queries"
                onChange={(e) => setMessage(e.target.value)}
                id=""
                cols="30"
                rows="10"
                className="message-txt"
              />
            </div>
          </div>
          <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
            <button
              onClick={() => setAddModal(false)}
              className="comn-btn bg-hash2 px-3 py-2 me-2"
            >
              Close
            </button>
            <button
              onClick={() => replyMessage()}
              type="button"
              className="comn-btn bg-greens px-3 py-2 me-2"
              data-dismiss="modal"
            >
              Reply
            </button>
          </div>
        </Modal>
        <Modal
          className="table-modal"
          show={editModal}
          onHide={() => setEditModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <div className="px-2 py-2 col-12 d-flex flex-wrap">
            <div className="px-1 col-sm-12 modal-label-input">
              <h4>Reply</h4>
            </div>
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Voium Niou
              </label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                className="message-txt"
                disabled
              >
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Laborum id recusandae ut officiis dolorum voluptates,
                necessitatibus at perferendis, delectus eveniet porro beatae
                excepturi vitae rerum vel temporibus fugit optio soluta.
              </textarea>
            </div>
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Description
              </label>
              <textarea name="" id="" cols="30" rows="10"></textarea>
            </div>
          </div>
          <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
            <button
              onClick={() => setEditModal(false)}
              className="comn-btn bg-hash2 px-3 py-2 me-2"
            >
              Cancel
            </button>
            <button
              type="button"
              className="comn-btn bg-greens px-3 py-2 me-2"
              data-dismiss="modal"
            >
              Submit
            </button>
          </div>
        </Modal>
      </article>
    </aside>
  );
};

export default HHelpSupportPage;
