import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

const AppointmentHistoryTable = ({ adminData }) => {
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [searcharr, setSearchArr] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [feedBackData, setFeedBackData] = useState({});

  useEffect(() => {
    let temp = [...adminData];
    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter(
        (val) =>
          val?.doctor?.name.toLowerCase().includes(searcharr.toLowerCase()) ||
          val?.speciality?.name.toLowerCase().includes(searcharr.toLowerCase())
      );
      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    // console.log(event);
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;
    setItemsPerPage(parseInt(value));
  };

  const verify = localStorage?.getItem("hospital_Token");

  const feedbackShow = (data) => {
    setFeedBackData(data);
    setAddModal(true);
  };

  console.log("adminData", adminData);

  return (
    <>
      <div className="ad-btn w-100 flex-ac-jb p-2">
        {/* <Form>
          <select onChange={setListItems} name="" id="">
            <option value="10">10</option>
            <option value={adminData.length}>All</option>
          </select>
        </Form> */}
        <div />
        <div className="flex-ac-jc">
          <Form className="search-input me-2">
            <input
              onChange={(e) => setSearchArr(e.target.value)}
              placeholder="search..."
              type="search"
              className="bg-transparent rounded-1 px-2 py-1"
            />
          </Form>
        </div>
      </div>

      <div className="p-2 bg-white rounded-2">
        <p
          className="permission-text txt-primary4 mb-1 mt-0 text-uppercase"
          for=""
        >
          {/* Upcoming */}
          Completed
        </p>
        <table className="custom-table">
          <thead>
            <tr className="text-center text-nowrap">
              <th>S.No</th>
              <th>Name</th>
              <th>Doctor</th>
              {verify ? null : <th>Hospital</th>}
              <th>Speciality</th>
              <th>Date</th>
              <th>Time</th>
              <th>Fees</th>
              <th>Admin Fees</th>
              {/* <th>End Time</th> */}
              <th className="text-center">Status</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((list, index) => (
              <tr key={index} className="text-center text-nowrap">
                <td>
                  {index == 9
                    ? last
                    : counter
                    ? `${counter}${index + 1}`
                    : index + 1}
                </td>

                <td>{list?.doctor?.name}</td>
                <td>{list?.doctor?.username}</td>
                {verify ? null : (
                  <td>
                    {list?.hospital?.name
                      ? list?.hospital?.name
                      : "Individual Doctor"}
                  </td>
                )}
                <td>{list.speciality.name}</td>
                <td>{moment(list?.from_time).format("DD-MM-YYYY")}</td>
                <td>
                  {moment(list?.from_time).format("hh:ss a") +
                    " to " +
                    moment(list?.to_time).format("hh:ss a")}
                </td>
                <td>{list?.transaction?.total_fees}</td>
                <td>{list?.transaction?.admin_fees}</td>
                {/* <td>{moment(list?.to_time).format("hh:ss a")}</td> */}
                <td className="text-center">
                  <button className={"comn-btn active-deact-btn bg-greens"}>
                    {list?.status == 4
                      ? "Acknowledged"
                      : list?.status == 3
                      ? "Completed"
                      : ""}
                  </button>
                </td>
                <td className="text-center">
                  <button
                    className="comn-btn bg-yellows edit-btn-svg"
                    onClick={() => feedbackShow(list)}
                  >
                    {" "}
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>

      <Modal
        className="table-modal"
        show={addModal}
        onHide={() => setAddModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>FeedBack</Modal.Title>
        </Modal.Header>
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Review
            </label>
            <input
              type="Name"
              value={feedBackData?.review}
              id="example-if-email"
              name="example-if-email"
              placeholder="Review"
            />
          </div>
          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Rating
            </label>
            {+feedBackData?.rating == 1 ? (
              <p style={{ color: "#FBC740", marginTop: "5px" }}>&#9733;</p>
            ) : +feedBackData?.rating == 2 ? (
              <p style={{ color: "#FBC740", marginTop: "5px" }}>
                &#9733;&#9733;
              </p>
            ) : +feedBackData?.rating == 3 ? (
              <p style={{ color: "#FBC740", marginTop: "5px" }}>
                &#9733; &#9733; &#9733;
              </p>
            ) : +feedBackData?.rating == 4 ? (
              <p style={{ color: "#FBC740", marginTop: "5px" }}>
                &#9733; &#9733; &#9733; &#9733;
              </p>
            ) : +feedBackData?.rating == 5 ? (
              <p style={{ color: "#FBC740", marginTop: "5px" }}>
                &#9733; &#9733; &#9733; &#9733; &#9733;
              </p>
            ) : (
              <p style={{ color: "#e4e4e4", marginTop: "5px" }}>
                &#9733; &#9733; &#9733; &#9733; &#9733;
              </p>
            )}
          </div>
        </div>
        {/* <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => {
              setAddModal(false);
            }}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            OK
          </button>
        </div> */}
      </Modal>
    </>
  );
};

export default AppointmentHistoryTable;
