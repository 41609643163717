import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../component/Header";

const Layout = () => {
  return (
    <div className="d-flex flex-wrap">
      <Header />
      <div className="out-left col ">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
