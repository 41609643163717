import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { bannerList } from "../assets/img";
import { bannerServices } from "../Services/Service/banner";
import ReactPaginate from "react-paginate";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import swal from "sweetalert";
import toast, { Toaster } from "react-hot-toast";
import usePermissions from "../Services/Storage/user";

const Bannerpage = () => {
  const { user } = usePermissions();
  const [adminData, setadminData] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [banner, setBanner] = useState();
  const [bannerPreview, setBannerPreview] = useState();
  const [view, setView] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [viewId, setViewId] = useState();
  const [searcharr, setSearchArr] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [bannerPreviewErr, setBannerPreviewErr] = useState(false);

  const notify = (error_msg) => {
    toast.error(error_msg);
  };

  useEffect(() => {
    let temp = [...adminData];

    console.log(temp);

    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.name.toLowerCase().includes(searcharr.toLowerCase())
      );

      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    console.log(event);
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;

    setItemsPerPage(parseInt(value));
  };

  const viewList = () => {
    bannerServices.bannerList().then((res) => {
      let temp = res.data?.banners.reverse();
      setadminData(temp);
      // swal(res.data.message, "", "success");

      // console.log(res);
    });
  };

  useEffect(() => {
    viewList();
  }, []);

  const addBanner = () => {
    if (name == 0) {
      setNameErr(true);
      setDescriptionErr(true);
      setBannerPreviewErr(true);
    } else {
      let formdata = new FormData();
      if (banner) {
        formdata.append("image", banner);
      }
      if (view) {
        formdata.append("_method", "PUT");
      }
      formdata.append("name", name);
      formdata.append("description", description);

      if (view) {
        if (name && description) {
          bannerServices
            .editBanner(viewId, formdata)
            .then((res) => {
              viewList();
              setEditModal(false);

              swal(res.data.message, "", "success");
            })
            .catch((e) => ErrorHandler(e));
        } else if (!name) {
          notify("Please Enter Name");
        } else if (!description) {
          notify("Please Enter Description");
        }
      } else {
        if (name && description && banner) {
          bannerServices
            .addBanner(formdata)
            .then((res) => {
              viewList();
              setEditModal(false);

              swal(res.data.message, "", "success");
            })
            .catch((e) => ErrorHandler(e));
        } else if (!name) {
          notify("Please Enter Name");
        } else if (!description) {
          notify("Please Enter Description");
        } else if (!banner) {
          notify("Please Select Image");
        }
      }
    }
  };
  const changeStatus = (id) => {
    bannerServices.statusBanner(id).then((res) => {
      viewList();
      swal(res.data.message, "", "success");
    });
  };

  const editBanner = (id) => {
    setView(true);
    setViewId(id);
    bannerServices
      .viewBanner(id)
      .then((res) => {
        // console.log(res.data);
        setName(res.data.banner?.name);
        setDescription(res.data.banner?.description);
        setBannerPreview(res.data?.banner?.img_url);
        setEditModal(true);
      })
      .catch((e) => {
        ErrorHandler(e);
      });
  };

  useEffect(() => {
    if (!banner) {
      setBannerPreview(undefined);
      return;
    }
    const profileUrl = URL.createObjectURL(banner);
    setBannerPreview(profileUrl);
  }, [banner]);

  const onClickImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setBanner(undefined);
      return;
    }
    setBanner(e.target.files[0]);
  };
  return (
    <aside>
      <TopHeader type="banner" />
      <article className="p-3">
        <Toaster position="top-right" reverseOrder={false} />

        <div className="ad-btn w-100 flex-ac-jb p-2">
          <div></div>
          {/* <Form>
            <select onChange={setListItems} name="" id="">
              <option value="10">10</option>
              <option value={adminData.length}>All</option>
            </select>
          </Form> */}
          <div className="flex-ac-jc">
            <Form className="search-input me-2">
              <input
                onChange={(e) => setSearchArr(e.target.value)}
                placeholder="search..."
                type="search"
                className="bg-transparent rounded-1 px-2 py-1"
              />
            </Form>
            {user?.includes("CAN-ADD-BANNERS") ? (
              <button
                onClick={() => {
                  setView(false);
                  setName("");
                  setDescription("");
                  setBannerPreview("");
                  setEditModal(true);
                }}
                className="comn-btn bg-primary1 px-3 py-2"
              >
                Add Banner
              </button>
            ) : null}
          </div>
        </div>
        <div className="p-2 bg-white rounded-2">
          <table className="custom-table">
            <thead>
              <tr className="text-center">
                <th>S.No</th>
                <th className="text-center">Image</th>
                <th>Name</th>
                <th>Description</th>
                {user?.includes("CAN-STATUS-BANNERS") ? (
                  <th className="text-center">Status</th>
                ) : null}
                {user?.includes("CAN-EDIT-BANNERS") ? (
                  <th className="text-center">Action</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((list, index) => (
                <tr key={index} className="text-center">
                  <td>
                    {index == 9
                      ? last
                      : counter
                      ? `${counter}${index + 1}`
                      : index + 1}
                  </td>
                  <td className="text-center">
                    <img className="table-img" src={list.img_url} />
                  </td>
                  <td>{list.name}</td>
                  <td>{list.description}</td>
                  {user?.includes("CAN-STATUS-BANNERS") ? (
                    <td className="text-center">
                      <button
                        onClick={() => changeStatus(list.id)}
                        className={
                          list.status == 1
                            ? "comn-btn active-deact-btn bg-greens"
                            : "comn-btn active-deact-btn bg-primary1"
                        }
                      >
                        {list.status == 1 ? "Active" : "Deactive"}
                      </button>
                    </td>
                  ) : null}
                  {user?.includes("CAN-EDIT-BANNERS") ? (
                    <td className="flex-ac text-center">
                      <button
                        onClick={() => editBanner(list.id)}
                        className="comn-btn bg-blues edit-btn-svg"
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </button>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-3  custom-pagi-nation d-flex justify-content-end align-items-center">
          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
        <Modal
          className="table-modal"
          show={addModal}
          onHide={() => setAddModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <div className="px-2 py-2 col-12 d-flex flex-wrap">
            <div className="px-1 col-sm-12 modal-label-input">
              <h4>Add Banner</h4>
            </div>
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Name
              </label>
              <input
                type="Name"
                id="example-if-email"
                name="example-if-email"
                placeholder="Name"
              />
            </div>
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Description
              </label>
              <textarea name="" id="" cols="30" rows="10">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Laborum id recusandae ut officiis dolorum voluptates,
                necessitatibus at perferendis, delectus eveniet porro beatae
                excepturi vitae rerum vel temporibus fugit optio soluta.
              </textarea>
            </div>
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Banner Image
              </label>
              <input type="file" onChange={onClickImage} />
              {banner && (
                <div className="w-100 prev-Image">
                  <img className="w-100" src={bannerPreview} />
                </div>
              )}
            </div>
          </div>
          <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
            <button
              onClick={() => setAddModal(false)}
              className="comn-btn bg-hash2 px-3 py-2 me-2"
            >
              Cancel
            </button>
            <button
              type="button"
              className="comn-btn bg-greens px-3 py-2 me-2"
              data-dismiss="modal"
            >
              Submit
            </button>
          </div>
        </Modal>
        <Modal
          className="table-modal"
          show={editModal}
          onHide={() => setEditModal(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <div className="px-2 py-2 col-12 d-flex flex-wrap">
            <div className="px-1 col-sm-12 modal-label-input">
              <h4>{view ? "Edit" : "Add"} Banner</h4>
            </div>
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Name *
              </label>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="Name"
                id="example-if-email"
                name="example-if-email"
                placeholder="Enetr The Name"
              />
              {name == 0 && nameErr && (
                <p className="err pe-1">Enter Banner Name</p>
              )}
            </div>
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Description *
              </label>
              <textarea
                onChange={(e) => setDescription(e.target.value)}
                name="description"
                placeholder="Enter The Description"
                value={description}
                cols="30"
                rows="10"
              />
              {description == 0 && descriptionErr && (
                <p className="err pe-1">Enter Description</p>
              )}
            </div>
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Banner Image *
              </label>
              <input type="file" onChange={onClickImage} />
              {!bannerPreview && bannerPreviewErr && (
                <p className="err pe-1">Choose Banner</p>
              )}
              {bannerPreview && (
                <div className="w-100 prev-Image">
                  <img className="w-100" src={bannerPreview} />
                </div>
              )}
            </div>
          </div>
          <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
            <button
              onClick={() => setEditModal(false)}
              className="comn-btn bg-hash2 px-3 py-2 me-2"
            >
              Cancel
            </button>
            <button
              onClick={addBanner}
              type="button"
              className="comn-btn bg-greens px-3 py-2 me-2"
              data-dismiss="modal"
            >
              Submit
            </button>
          </div>
        </Modal>
      </article>
    </aside>
  );
};

export default Bannerpage;
