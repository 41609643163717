import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { maleAvatar } from "../assets/img";
import HospitalCategories from "../component/HospitalCategories";
import PatientAppoinment from "../component/PatientAppoinment";
import Specialities from "../component/Specialities";
import TopHeader from "../component/TopHeader";
import TopHeader1 from "../component/TopHeader1";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import { hospital } from "../Services/Service/hospital";
import swal from "sweetalert";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const HEditDoctor = () => {
  const animatedComponents = makeAnimated();

  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState();
  const [profilePreview, setProfilePreview] = useState();
  const [licence, setLicence] = useState();
  const [licencePreview, setLicencePreview] = useState();
  const [specialities, setSpecialities] = useState([]);
  const [inputs, setInputs] = useState();
  const [selected, setSelected] = useState([]);

  const [mui, setMui] = useState();
  const [muiPreview, setMuiPreview] = useState();

  const location = useLocation();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInputs((values) => ({ ...values, [name]: value }));
  };

  // useEffect(() => {
  //   hospital.hospitalCreate().then((res) => {
  //     setSpecialities(res.data.specializations);
  //   });
  // }, []);
  // console.log(selected);
  const viewDoctor = () => {
    let data = {};
    let temp = [];
    let speciality_list = [];

    hospital.hospitalCreate().then((res) => {
      res?.data?.specializations?.map((list) => {
        speciality_list.push({ value: list.id, label: list.name });
      });

      setSpecialities(speciality_list);
    });

    hospital.doctorEdit(location?.state?.id).then((res) => {
      res.data?.speciality_ids?.map((list) => {
        temp.push(list);
      });
      let select_id = speciality_list.filter((val) => {
        if (temp.includes(val.value)) {
          return true;
        }
        return false;
      });
      setSelected(select_id);

      data.name = res.data.doctor?.name;
      data.qualification = res.data.doctor?.qualification;
      data.experience = res.data.doctor?.experience;
      data.username = res.data.doctor?.username;
      // data.password = res.data.doctor?.name;
      setProfilePreview(res.data.doctor?.img_url);
      data.interval_min = res.data.doctor?.interval_mins;
      data.total_fees = res.data.doctor?.total_fees;
      data.description = res.data.doctor?.detail.description;
      data.mobile = res.data.doctor.detail?.mobile;
      data.email = res.data.doctor.detail?.email;
      data.age = res.data.doctor.detail?.age;
      data.gender = res.data.doctor.detail?.gender;
      // data.rating = res.data.doctor?.rating;
      // data.specialist = res.data?.speciality_ids[0];

      data.detail_id = res.data.doctor.detail.id;
      data.license_id = res.data.doctor.upload.id;

      // console.log(data);
      setInputs(data);
    });
  };
  // console.log(inputs);

  useEffect(() => {
    if (location.state.id) {
      viewDoctor();
    }
  }, []);

  const notify = (error_msg) => {
    toast.error(error_msg);
  };

  const editDoctor = () => {
    let formData = new FormData();

    formData.append("_method", "PUT");

    formData.append("name", inputs?.name);
    formData.append("qualification", inputs?.qualification);
    formData.append("experience", inputs?.experience);
    formData.append("username", inputs?.username);
    if (inputs?.password) {
      formData.append("password", inputs?.password);
    }
    if (profileImage) {
      formData.append("image", profileImage);
      formData.append("license", profileImage);
    }
    formData.append("interval_min", parseInt(inputs?.interval_min));
    formData.append("total_fees", inputs?.total_fees);
    formData.append("description", inputs?.description);
    formData.append("mobile", parseInt(inputs?.mobile));
    formData.append("email", inputs?.email);
    formData.append("age", inputs?.age);
    formData.append("gender", inputs?.gender);
    formData.append("rating", 6);
    // formData.append("speciality_ids[0]", inputs?.specialist);

    formData.append("detail_id", inputs?.detail_id);
    formData.append("license_id", inputs?.license_id);

    selected?.map((list, index) => {
      formData.append(`speciality_ids[${index}]`, list.value);
    });

    // hospital
    //   .doctorUpdate(location.state.id, formData)
    //   .then((res) => {
    //     // console.log(res.data.message);
    //     swal(res.data.message, "", "success");
    //   })
    //   .catch((e) => {
    //     ErrorHandler(e);
    //   });

    if (
      inputs?.name &&
      inputs?.qualification &&
      inputs?.experience &&
      inputs?.username &&
      inputs?.interval_min &&
      inputs?.total_fees &&
      inputs?.description &&
      inputs?.mobile &&
      inputs?.email &&
      inputs?.gender &&
      // inputs?.rating &&
      selected.length >= 1 &&
      inputs?.age
    ) {
      hospital
        .doctorUpdate(location.state.id, formData)
        .then((res) => {
          // console.log(res.data.message);
          swal(res.data.message, "", "success");
          navigate("/doctors");
        })
        .catch((e) => {
          ErrorHandler(e);
        });
    } else if (!inputs?.name) {
      notify("Please Enter Name");
    } else if (!inputs?.username) {
      notify("Please Enter UserName");
    } else if (!inputs?.mobile) {
      notify("Please Enter Mobile Number");
    } else if (!inputs?.email) {
      notify("Please Enter Email Address");
    } else if (selected.length < 1) {
      notify("Please Select a Cateogry");
    } else if (!inputs?.qualification) {
      notify("Please Enter Qualification");
    } else if (!inputs?.total_fees) {
      notify("Please Enter Fee Amount");
    } else if (!inputs?.interval_min) {
      notify("Please Enter Duration");
    }
    // else if (!inputs?.rating) {
    //   notify("Please Enter Rating");
    // }
    else if (!inputs?.experience) {
      notify("Please Enter Experience");
    } else if (!inputs?.age) {
      notify("Please Enter Age ");
    } else if (!inputs?.gender) {
      notify("Please Enter Gender");
    } else if (!inputs?.description) {
      notify("Please Enter Description");
    }
  };

  useEffect(() => {
    if (!mui) {
      setMuiPreview(undefined);
      return;
    }
    const profileUrlll = URL.createObjectURL(mui);
    setMuiPreview(profileUrlll);
  }, [mui]);

  useEffect(() => {
    if (!licence) {
      setLicencePreview(undefined);
      return;
    }
    const profileUrll = URL.createObjectURL(licence);
    setLicencePreview(profileUrll);
  }, [licence]);

  useEffect(() => {
    if (!profileImage) {
      setProfilePreview(undefined);
      return;
    }
    const profileUrl = URL.createObjectURL(profileImage);
    setProfilePreview(profileUrl);
  }, [profileImage]);

  const onClickMui = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setMui(undefined);
      return;
    }
    setMui(e.target.files[0]);
  };
  const onClickLicence = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setLicence(undefined);
      return;
    }
    setLicence(e.target.files[0]);
  };
  const onClickImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setProfileImage(undefined);
      return;
    }
    setProfileImage(e.target.files[0]);
  };

  return (
    <aside className="">
      <TopHeader1 type="edit-doctor" />
      <article>
        <Toaster position="top-right" reverseOrder={false} />

        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
              <div className="form-check ps-0">
                <div className="px-1 col-sm-12 modal-label-input">
                  <label className="text-primary-dark" for="">
                    Autority Name
                  </label>
                  <select name="" id="" className="bg-background">
                    <option value="Ramesh">Ramesh</option>
                    <option value="Rahesh">Rahesh</option>
                  </select>
                </div>
              </div>
            </div> */}
            <Form className="d-flex flex-wrap">
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Name
                    </label>
                    <input
                      onChange={handleChange}
                      value={inputs?.name}
                      type="text"
                      id="example-if-email"
                      name="name"
                      placeholder="Name"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      UserName
                    </label>
                    <input
                      disabled={true}
                      onChange={handleChange}
                      type="text"
                      id="example-if-email"
                      value={inputs?.username}
                      name="username"
                      placeholder="userName"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Mobile
                    </label>
                    <input
                      onChange={handleChange}
                      type="Name"
                      id="example-if-email"
                      value={inputs?.mobile}
                      name="mobile"
                      placeholder="mobile"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Email
                    </label>
                    <input
                      onChange={handleChange}
                      type="email"
                      id="example-if-email"
                      value={inputs?.email}
                      name="email"
                      placeholder="Name"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Specialities
                    </label>
                    <select
                      value={inputs?.specialist}
                      onChange={handleChange}
                      name="specialist"
                      id=""
                      className=""
                    >
                      <option value="">Select specialist</option>
                      {specialities.map((list, i) => {
                        return <option value={list.id}>{list.name}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div> */}
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Qualification
                    </label>
                    <input
                      value={inputs?.qualification}
                      onChange={handleChange}
                      type="text"
                      id="example-if-email"
                      name="qualification"
                      placeholder="Qualification"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Experience ( Year )
                    </label>
                    <input
                      value={inputs?.experience}
                      onChange={handleChange}
                      type="text"
                      id="example-if-email"
                      name="experience"
                      placeholder="Name"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Appointment Fee ( ₹ )
                    </label>
                    <input
                      value={inputs?.total_fees}
                      onChange={handleChange}
                      type="text"
                      id="example-if-email"
                      name="total_fees"
                      placeholder="Name"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Duration ( min )
                    </label>
                    <input
                      value={inputs?.interval_min}
                      onChange={handleChange}
                      type="number"
                      id="example-if-email"
                      name="interval_min"
                      placeholder="Name"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>

              {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Ratings
                    </label>
                    <input
                      value={inputs?.rating}
                      onChange={handleChange}
                      type="number"
                      id="example-if-email"
                      name="rating"
                      placeholder="Name"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div> */}

              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Age
                    </label>
                    <input
                      value={inputs?.age}
                      onChange={handleChange}
                      type="number"
                      id="example-if-email"
                      name="age"
                      placeholder="Age"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Gender
                    </label>
                    <input
                      value={inputs?.gender}
                      onChange={handleChange}
                      type="text"
                      id="example-if-email"
                      name="gender"
                      placeholder="Gender"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Password
                    </label>
                    <input
                      value={inputs?.password}
                      onChange={handleChange}
                      type="password"
                      id="example-if-email"
                      name="password"
                      placeholder="Password"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>

              <div className="form-group col-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Description
                    </label>
                    <textarea
                      value={inputs?.description}
                      onChange={handleChange}
                      className="bg-transparent"
                      name="description"
                      id=""
                      cols="30"
                      rows="10"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Specialities
                    </label>
                    <Select
                      onChange={(e) => setSelected(e)}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      value={selected}
                      // defaultValue={[selected]}
                      isMulti
                      options={specialities}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-4 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Profile Image
                    </label>
                    <input type="file" onChange={onClickImage} />
                    {profilePreview && (
                      <div className="w-90 prev-Image">
                        <img className="w-100" src={profilePreview} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="form-group col-12 col-sm-6 col-lg-4 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Licence Image
                    </label>
                    <input type="file" onChange={onClickLicence} />
                    {licence && (
                      <div className="w-90 prev-Image">
                        <img className="w-100" src={licencePreview} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-4 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      MUI
                    </label>
                    <input type="file" onChange={onClickMui} />
                    {mui && (
                      <div className="w-90 prev-Image">
                        <img className="w-100" src={muiPreview} />
                      </div>
                    )}
                  </div>
              </div>
                </div> */}
            </Form>
            <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
              {/* <button className="comn-btn bg-hash2 px-3 py-2 me-2">
                Cancel
              </button> */}
              <button
                onClick={editDoctor}
                type="button"
                className="comn-btn bg-blues px-3 py-2 me-2"
                data-dismiss="modal"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </article>
      {/* <HospitalCategories /> */}
    </aside>
  );
};

export default HEditDoctor;
