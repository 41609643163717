// @ts-nocheck
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  adminIcon,
  AppointmentIcon,
  BannerIcon,
  categoryIcon,
  dashbaordIcon,
  doctorIcon,
  FaqIcon,
  HelpIcon,
  hospitalIcon,
  Logout,
  NotifIcon,
  patientIcon,
  PrivacyIcon,
  RequestIcon,
  roleIcon,
  TermsCondiIcon,
} from "../assets/img";
import { useLazyLogoutQuery } from "../redux/api/api";

const Navbar1 = () => {
  const [editModal, setEditModal] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const navigate = useNavigate();

  // RTK Query

  const [logout] = useLazyLogoutQuery();

  const logoutAccount = () => {
    console.log("Logout");
    logout()
      .unwrap()
      .then((res) => {
        console.log("res", res);
        if (res?.status == "success") {
          navigate("/signin");
          localStorage.clear();
          setTimeout(() => {
            window.location.reload();
          }, 400);
        }
      });
  };

  const logoutAccount1 = () => {
    navigate("/signin");
    localStorage.clear();

    // location.reload();
    // window.alert("Logout successFully");

    window.location.href = "/";
  };
  return (
    <nav>
      <ul>
        <li>
          <Link
            type="crm-dashboard"
            className={
              splitLocation[1] === "hospitals"
                ? "menu-list1 active"
                : "menu-list1"
            }
            // to="/hospitals"
            to="/">
            <img className="menu-icon" src={dashbaordIcon} />
            Dashboards
          </Link>
        </li>
        <li>
          <Link
            className={
              splitLocation[1] === "doctors"
                ? "menu-list1 active"
                : "menu-list1"
            }
            to="/doctors">
            <img className="menu-icon" src={doctorIcon} />
            Doctors
          </Link>
        </li>
        <li>
          <Link
            className={
              splitLocation[1] === "appoinment-list"
                ? "menu-list1 active"
                : "menu-list1"
            }
            to="/appoinment-list">
            <img className="menu-icon" src={AppointmentIcon} />
            Appoinments
          </Link>
        </li>
        <li>
          <Link
            className={
              splitLocation[1] === "patient-list"
                ? "menu-list1 active"
                : "menu-list1"
            }
            to="/patient-list">
            <img className="menu-icon" src={patientIcon} />
            Pateints
          </Link>
        </li>

        <li>
          <Link
            className={
              splitLocation[1] === "report-list"
                ? "menu-list1 active"
                : "menu-list1"
            }
            to="/report-list">
            <img className="menu-icon" src={RequestIcon} />
            Report
          </Link>
        </li>

        <li>
          <Link
            to="/help-supports"
            className={
              splitLocation[1] === "help-supports"
                ? "menu-list1 active"
                : "menu-list1"
            }>
            <img className="menu-icon" src={HelpIcon} />
            Admin Help & Support
          </Link>
        </li>
        <li>
          <Link
            to="/doctor-help-supports"
            className={
              splitLocation[1] === "doctor-help-supports"
                ? "menu-list1 active"
                : "menu-list1"
            }>
            <img className="menu-icon" src={HelpIcon} />
            Doctor Help & Support
          </Link>
        </li>
        <li>
          <Link onClick={logoutAccount} className="menu-list1">
            <img className="menu-icon" src={Logout} />
            Logout
          </Link>
        </li>
      </ul>
      <Modal
        className="table-modal"
        show={editModal}
        onHide={() => setEditModal(false)}
        aria-labelledby="example-modal-sizes-title-lg">
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Help and Support</h4>
          </div>
          <div className="px-1 col-sm-12 modal-label-input textarea-height">
            <label className="text-primary-dark" for="">
              Description
            </label>
            <textarea name="" id="" cols="30" rows="10"></textarea>
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => setEditModal(false)}
            className="comn-btn bg-hash2 px-3 py-2 me-2">
            Cancel
          </button>
          <button
            type="button"
            className="comn-btn bg-greens px-3 py-2 me-2"
            data-dismiss="modal">
            Submit
          </button>
        </div>
      </Modal>
    </nav>
  );
};

export default Navbar1;
