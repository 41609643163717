import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { bannerList, maleAvatar } from "../assets/img";
import DoctorTreatment from "../component/DoctorTreatment";
import PatientAppoinment from "../component/PatientAppoinment";
import TopHeader from "../component/TopHeader";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import { doctor } from "../Services/Service/doctor";
import { hospital } from "../Services/Service/hospital";

const DoctorRequest = () => {
  const [mui, setMui] = useState();
  const [list, setList] = useState({});
  console.log(list);
  const navigate = useNavigate();
  const [categories, setCateogries] = useState([
    { value: "Ortho", label: "Chocolate" },
    { value: "Dentist", label: "Strawberry" },
  ]);
  const [muiPreview, setMuiPreview] = useState();
  const [btn, setBtn] = useState(false);
  const location = useLocation();

  const viewRequest = () => {
    doctor
      .viewDoctor(location?.state?.id)
      .then((res) => {
        let temp = [];
        let speciality_list = [];

        hospital.hospitalCreate().then((res) => {
          res?.data?.specializations?.map((list) => {
            speciality_list.push({ value: list.id, label: list.name });
          });
        });
        res?.data?.doctor?.speacialists?.map((list) => {
          temp.push(list);
        });

        let selected = speciality_list.filter((val) => {
          if (temp.includes(val.value)) {
            return true;
          }
          return false;
        });
        setCateogries(selected);
        setList(res.data.doctor);
      })
      .catch((e) => {
        ErrorHandler(e);
      });
  };

  const actionRequest = (status) => {
    setBtn(true);
    doctor
      .processDoctor(location?.state?.id, status)
      .then((res) => {
        setBtn(false);
        alert(res.data.message);
        navigate("/doctor");
      })
      .catch((e) => {
        setBtn(false);
        ErrorHandler(e);
      });
  };

  useEffect(() => {
    if (location?.state?.id) {
      viewRequest();
    }
  }, []);

  useEffect(() => {
    if (!mui) {
      setMuiPreview(undefined);
      return;
    }
    const profileUrlll = URL.createObjectURL(mui);
    setMuiPreview(profileUrlll);
  }, [mui]);

  const onClickMui = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setMui(undefined);
      return;
    }
    setMui(e.target.files[0]);
  };

  return (
    <aside className="">
      <TopHeader type="doctor-request" />
      <article>
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <div className="flex-ac-jb">
              <p
                className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                for=""
              >
                {list?.name}
              </p>
              <img
                className="avatar-img"
                src={list?.img_url ? list?.img_url : maleAvatar}
              />
            </div>
            <Form className="d-flex flex-wrap">
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Name
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="example-if-email"
                      placeholder="Name"
                      value={list?.name}
                      disabled
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Mobile
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="example-if-email"
                      placeholder="Mobile"
                      value={list?.detail?.mobile}
                      disabled
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Email
                    </label>
                    <input
                      type="email"
                      id="example-if-email"
                      name="example-if-email"
                      placeholder="Email"
                      value={list?.detail?.email}
                      disabled
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div>
              {list?.detail?.gender && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Gender
                      </label>
                      <input
                        type="Name"
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Gender"
                        value={list?.detail?.gender}
                        className="bg-transparent"
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.experience && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Total Experience
                      </label>
                      <input
                        // type="date"
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Total Experience"
                        value={list?.experience}
                        className="bg-transparent"
                      />
                    </div>
                  </div>
                </div>
              )}
              {categories?.length !== 0 && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Specialities
                      </label>
                      <input
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Specialities"
                        value={categories?.map((list) => {
                          return list.value;
                        })}
                        className="bg-transparent"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.total_fees && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Appointment Fee ( ₹ )
                      </label>
                      <input
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Appointment Fee"
                        value={list?.total_fees}
                        className="bg-transparent"
                      />
                    </div>
                  </div>
                </div>
              )}
              {list?.interval_mins && (
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Duration ( min )
                      </label>
                      <input
                        id="example-if-email"
                        name="example-if-email"
                        placeholder="Duration"
                        value={list?.interval_mins}
                        className="bg-transparent"
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Address
                    </label>
                    <input
                      // type="date"
                      id="example-if-email"
                      name="example-if-email"
                      placeholder="Address"
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="form-group col-12 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Description
                    </label>
                    <input
                      // type="date"
                      id="example-if-email"
                      name="example-if-email"
                      placeholder="Description"
                      value={list?.detail?.description}
                      className="bg-transparent"
                    />
                  </div>
                </div>
              </div> */}
              {list?.upload?.img_url && (
                <div className="form-group col-12 col-sm-12 col-lg-7 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Licence
                      </label>
                      <div className="w-90 prev-Image">
                        <img className="w-100" src={list?.upload?.img_url} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Form>
          </div>
        </div>
      </article>
      {/* <DoctorTreatment /> */}
      <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
        <button
          onClick={() => actionRequest(2)}
          className="comn-btn bg-primary2 px-3 py-2 me-2"
        >
          Reject
        </button>
        <button
          onClick={() => actionRequest(1)}
          type="button"
          className="comn-btn bg-greens px-3 py-2 me-2"
          data-dismiss="modal"
        >
          Accept
        </button>
      </div>
    </aside>
  );
};

export default DoctorRequest;
