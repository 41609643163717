import { URL } from "../Constant/URL";
import httpClient from "../Constant/httpClient";

const faqList = () => {
  return httpClient.get(URL.FAQ_LIST);
};

const addfaq = (data) => {
  return httpClient.post(URL.FAQ_LIST, data);
};

const viewfaq = (id) => {
  return httpClient.get(URL.FAQ_VIEW + id);
};

const editfaq = (id, data) => {
  return httpClient.post(URL.FAQ_VIEW + id, data);
};

const statusfaq = (id) => {
  return httpClient.get(URL.FAQ_VIEW + "status" + "/" + id);
};

export const faqServices = {
  statusfaq,
  faqList,
  addfaq,
  viewfaq,
  editfaq,
};
