import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TopHeader from "../component/TopHeader";
import { appointment } from "../Services/Service/appoinment";
import ErrorHandler from "../Services/Constant/ErrorHandler";
import AppointmentTable from "../component/AppointmentTable";
import AppointmentHistoryTable from "../component/AppointmentHistoryTable";
import AppointmentRequest from "../component/AppointmentRequest";

const HAppoinmentPage = () => {
  const [historyData, setHistoryData] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [upComingData, setUpComingData] = useState([]);
  const [apiReload, setApiReload] = useState(false);
  // const filterHistory = historyData?.filter((e) => e.status == 4);
  const apiData = () => {
    appointment.appointmentHistory().then((res) => {
      // console.log(res?.data?.appointments);
      setHistoryData(res?.data?.appointments);
    });
    appointment.rescheduleHistory().then((res) => {
      console.log(res?.data?.appointments);
      setRequestData(res?.data?.appointments);
    });
    appointment.upcomingsHistory().then((res) => {
      // console.log(res?.data?.appointments);
      setUpComingData(res?.data?.appointments);
    });
  };
  useEffect(() => {
    apiData();
  }, []);

  const apiReloadHandler = () => {
    if (apiReload == true) {
      apiData();
      setApiReload(false);
    }
  };
  return (
    <aside>
      <TopHeader type="appointment" />
      <Tabs
        defaultActiveKey="history"
        id="fill-tab-example"
        className="tab-appoinment"
        fill
      >
        <Tab eventKey="history" title="History">
          <AppointmentHistoryTable adminData={historyData} />
        </Tab>
        <Tab eventKey="request" title="Request">
          <AppointmentRequest
            adminData={requestData}
            reloadBtn={apiReloadHandler()}
            setReload={setApiReload}
          />
        </Tab>
        <Tab eventKey="upcomming" title="Upcoming" className="txt-primary2">
          <AppointmentTable
            adminData={upComingData}
            appointmentType="hospital"
          />
        </Tab>
      </Tabs>
    </aside>
  );
};

export default HAppoinmentPage;
