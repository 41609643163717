import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import "./responsive.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./routes/Home";
import Layout from "./Layout/Layout";
import AdminPage from "./routes/AdminPage";
import RolesPage from "./routes/RolesPage";
import AddRoles from "./routes/AddRoles";
import PatientPage from "./routes/PatientPage";
import PatientDetail from "./routes/PatientDetail";
import HospitalPage from "./routes/HospitalPage";
import HospitalDetail from "./routes/HospitalDetail";
import Categoriespage from "./routes/Categoriespage";
import Bannerpage from "./routes/Bannerpage";
import BlogPage from "./routes/BlogPage";
import AppoinmentPage from "./routes/AppoinmentPage";
import Faqpage from "./routes/Faqpage";
import HelpSupportPage from "./routes/HelpSupportPage";
import ReportPage from "./routes/ReportPage";
import NotificationPage from "./routes/Notificationpage";
import SignIn from "./views/auth/SignIn";
import HospitalAdd from "./routes/HospitalAdd";
import HospitalEdit from "./routes/HospitalEdit";
import Layout1 from "./Layout/Layout1";
import HHome from "./routes/HHome";
import HAppoinmentPage from "./routes/HAppoinmentPage";
import HDoctorPage from "./routes/HDoctorPage";
import HAddDoctor from "./routes/HAddDoctor";
import HViewDoctor from "./routes/HViewDoctor";
import HEditDoctor from "./routes/HEditDoctor";
import HPatientPage from "./routes/HPatientPage";
import HReportPage from "./routes/HReportPage";
import DoctorPage from "./routes/DoctorPage";
import DoctorRequest from "./routes/DoctorRequest";
import DoctorDetails from "./routes/DoctorDetails";
import useToken from "./Services/Storage/useToken";
import PrivateRoutes from "./component/PrivateRoutes";
import HHelpSupportPage from "./routes/HHelpSupportPage copy";
import HospitalSupport from "./routes/HospitalSupport";
import HospitalSupportDoctor from "./routes/HospitalSupportDoctor";
import NotificationHospital from "./routes/NotificationHospital";
import HospitalAddcopy from "./routes/HospitalAddcopy";
import HospitalDetailcopy from "./routes/HospitalDetailcopy";

const ReactRoutes = () => {
  const { token, setToken } = useToken();
  let temp = localStorage.getItem("hospital_Token");

  return (
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route element={<PrivateRoutes token={token} />}>
        <Route element={temp ? <Layout1 /> : <Layout />}>
          <Route path="/" index element={temp ? <HHome /> : <Home />} />
        </Route>

        <Route path="/change-password" element={<SignIn />} />
        <Route element={<Layout />}>
          <Route path="/admin" element={<AdminPage />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/roles" element={<RolesPage />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/roles/add-roles" element={<AddRoles />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/patient" element={<PatientPage />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/patient/patient-details" element={<PatientDetail />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/doctor" element={<DoctorPage />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/doctor/doctor-request" element={<DoctorRequest />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/doctor/doctor-detail" element={<DoctorDetails />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/hospital" element={<HospitalPage />} />
        </Route>
        {/* view Hospital start*/}
        {/* <Route element={<Layout />}>
          <Route
            path="/hospital/hospital-details"
            element={<HospitalDetail />}
          />
        </Route> */}

        <Route element={<Layout />}>
          <Route
            path="/hospital/hospital-details"
            element={<HospitalDetailcopy />}
          />
        </Route>
        {/* view Hospital end*/}

        {/* add Hospital start */}
        {/* <Route element={<Layout />}>
          <Route path="/hospital/add-hospital" element={<HospitalAdd />} />
        </Route> */}
        <Route element={<Layout />}>
          <Route path="/hospital/add-hospital" element={<HospitalAddcopy />} />
        </Route>
        {/* Add Hospital end */}

        <Route element={<Layout />}>
          <Route path="/hospital/hospital-edit" element={<HospitalEdit />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/categories" element={<Categoriespage />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/banners" element={<Bannerpage />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/blog" element={<BlogPage />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/appointment" element={<AppoinmentPage />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/faq" element={<Faqpage />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/help-support" element={<HelpSupportPage />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/report" element={<ReportPage />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/notification" element={<NotificationPage />} />
        </Route>

        <Route element={<Layout1 />}>
          <Route path="/hospitals" element={<HHome />} />
        </Route>
        <Route element={<Layout1 />}>
          <Route path="/doctors" element={<HDoctorPage />} />
        </Route>
        <Route element={<Layout1 />}>
          <Route path="/doctors/add-doctor" element={<HAddDoctor />} />
        </Route>
        <Route element={<Layout1 />}>
          <Route path="/doctors/view-doctor" element={<HViewDoctor />} />
        </Route>
        <Route element={<Layout1 />}>
          <Route path="/doctors/edit-doctor" element={<HEditDoctor />} />
        </Route>
        <Route element={<Layout1 />}>
          <Route path="/appoinment-list" element={<HAppoinmentPage />} />
        </Route>
        <Route element={<Layout1 />}>
          <Route path="/patient-list" element={<HPatientPage />} />
        </Route>
        <Route element={<Layout1 />}>
          <Route path="/report-list" element={<HReportPage />} />
        </Route>
        <Route element={<Layout1 />}>
          <Route path="/hospitals/patients" element={<HHome />} />
        </Route>
        <Route element={<Layout1 />}>
          <Route path="/hospitals/reports" element={<HHome />} />
        </Route>
        <Route element={<Layout1 />}>
          <Route path="/help-supports" element={<HospitalSupport />} />
        </Route>
        <Route element={<Layout1 />}>
          <Route
            path="/doctor-help-supports"
            element={<HospitalSupportDoctor />}
          />
        </Route>
        <Route element={<Layout1 />}>
          <Route path="/notifications" element={<NotificationHospital />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default ReactRoutes;
