import React from "react";
import { useState } from "react";
import { Button, Col, Form, Image } from "react-bootstrap";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import swal from "sweetalert";

import { eyeICon, eyeICon1, LoginLeft, Logo } from "../../assets/img";
import ErrorHandler from "../../Services/Constant/ErrorHandler";
import httpClient from "../../Services/Constant/httpClient";
import { LoginServices } from "../../Services/Service/login";
import usePermissions from "../../Services/Storage/user";
import useToken from "../../Services/Storage/useToken";
import {
  useChangePasswordMutation,
  useLoginMutation,
} from "../../redux/api/api";

const SignIn = () => {
  const loacation = useLocation();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [btn, setBtn] = useState(false);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { token, setToken } = useToken();
  const { setUser } = usePermissions();

  //  RTK QUERY
  const [login] = useLoginMutation();

  const [changePass] = useChangePasswordMutation();

  if (token) {
    httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  const handleLogin = () => {
    if (username !== "" && password !== "") {
      setBtn(true);
      let formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);
      formData.append("type", 1);
      login(formData)
        .unwrap()
        .then((res) => {
          console.log("formData", res);
          if (res?.status == "success") {
            console.log("res", res);
            setToken(res?.token);
            setUser(res?.permissions);
            localStorage.setItem("token", res?.token);
            localStorage.setItem(
              "permissions",
              JSON.stringify(res?.permissions)
            );
            setTimeout(() => {
              navigate("/");
              window.location.reload();
            }, 500);
          }
          setBtn(false);
        })
        .catch((error) => {
          setBtn(false);
          console.log("error", error?.message);
          swal(error?.message);
        });
    } else if (!username) {
      alert("Please enter your username ");
    } else if (!password) {
      alert("Please enter your password");
    }
  };

  const hospitalLogin = () => {
    if (username !== "" && password !== "") {
      let formData = new FormData();
      formData.append("username", username);
      formData.append("password", password);
      formData.append("type", 2);
      login(formData)
        .unwrap()
        .then((res) => {
          console.log("formData", res);
          if (res?.status == "success") {
            console.log("res", res);
            setToken(res?.token);
            localStorage.setItem("hospital_Token", res?.token);
            setTimeout(() => {
              navigate("/");
              window.location.reload();
            }, 500);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else if (!username) {
      alert("Please enter your username ");
    } else if (!password) {
      alert("Please enter your password");
    }
  };

  const changePassword = () => {
    if (cPassword !== "" && password !== "") {
      let formData = new FormData();
      formData.append("old_password", password);
      formData.append("password", cPassword);

      if (searchParams?.get("type") == "hospital") {
        formData.append("type", 2);
      } else {
        formData.append("type", 1);
      }
      setBtn(true);
      changePass(formData)
        .unwrap()
        .then((res) => {
          setBtn(false);
          console.log("formData", res);
          if (res?.status == "success") {
            console.log("res", res);
            setTimeout(() => {
              navigate("/");
              window.location.reload();
            }, 500);
          }
        })
        .catch((error) => {
          setBtn(false);
          console.log("error", error);
        });
    } else if (!password) {
      swal("Please enter your password ", "", "error");
    } else if (!cPassword) {
      swal("Please enter your  Confirm Password", "", "error");
    }
  };

  const passwordShowHandler = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Col xs={12} className="d-flex vh-100 log-in">
      <Col xs={12} md={6} className="left-log-in bg-blues">
        <div className="log-in-left">
          <Image className="" src={LoginLeft} />
        </div>
      </Col>
      <Col xs={12} md={6} className="vh-100 flex-ac-jc bg-background1">
        <div className="bg-background rounded-3 px-4 py-5 w-75 login-box">
          <div className="flex-ac-jc">
            <Image className="log-logo me-2" src={Logo} />
            <h4 className="logo-name">Pariseva</h4>
          </div>
          <h4 className="sign-in-text txt-primary4 mb-0 mt-0">
            {loacation.pathname == "/change-password"
              ? "Change Password"
              : "Sign In"}
          </h4>
          <p className="welcome-sign">
            {loacation.pathname == "/change-password"
              ? ""
              : "Welcome, Please Signin"}
          </p>
          {loacation.pathname !== "/change-password" ? (
            <div className="px-0 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                User Name *
              </label>
              <input
                onChange={(e) => setUserName(e.target.value)}
                type="text"
                id="example-if-email"
                name="example-if-email"
                placeholder="Enter The UserName"
                className="px-2 mt-1"
              />
            </div>
          ) : null}

          {loacation.pathname !== "/change-password" ? (
            <div className="px-0 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Password *
              </label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                type={!showPassword ? "password" : "text"}
                id="example-if-email"
                name="example-if-email"
                placeholder="Enter The Password"
                className="px-2 mt-1"
              />
              <button onClick={passwordShowHandler} className="eye-btn">
                <img
                  src={!showPassword ? eyeICon1 : eyeICon}
                  className="log-in-eye"
                />
              </button>
            </div>
          ) : null}
          {loacation.pathname == "/change-password" ? (
            <div className="px-0 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Old Password *
              </label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                id="example-if-email"
                name="example-if-email"
                placeholder="Enter The Old Password"
                className="px-2 mt-1"
              />
            </div>
          ) : null}
          {loacation.pathname == "/change-password" ? (
            <div className="px-0 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                New Password *
              </label>
              <input
                onChange={(e) => setCPassword(e.target.value)}
                type="password"
                id="example-if-email"
                name="example-if-email"
                placeholder="Enter The New Password"
                className="px-2 mt-1"
              />
            </div>
          ) : null}

          {loacation.pathname !== "/change-password" ? (
            <div className="d-flex">
              <button
                disabled={btn}
                onClick={handleLogin}
                className="comn-btn border-0 px-3 py-2 bg-primary1 text-white rounded-1 text-decoration-none me-2"
              >
                Admin
              </button>
              <button
                onClick={hospitalLogin}
                className="comn-btn border-0 px-3 py-2 bg-primary1 text-white rounded-1 text-decoration-none"
              >
                Hospital
              </button>
            </div>
          ) : null}
          {loacation.pathname == "/change-password" ? (
            <div className="d-flex justify-content-center">
              <button
                disabled={btn}
                onClick={changePassword}
                className="comn-btn border-0 px-3 py-2 bg-primary1 text-white rounded-1 text-decoration-none me-2"
              >
                Change Password
              </button>
            </div>
          ) : null}

          <p className="d-flex justify-content-end">V 0.2</p>
        </div>
      </Col>
      {/* <Col sm={12} className="d-flex justify-content-end">
        V 0.02
      </Col> */}
    </Col>
  );
};

export default SignIn;
